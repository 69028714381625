

import { useEffect, useState } from "react";
import Header from "../Header/Header";
import Siderbar from "../Sidebar/Sidebar";
import { MainCotent } from "../MainCotent/MainCotent";
import { NoOfAnimalAccident } from "../MainCotent/NoOfAnimalAccident/NoOfAnimalAccident";
import { SeasonWiseAnimalBar } from "../MainCotent/SeasonWiseAnimalBar/SeasonWiseAnimalBar";
import { StateWiseAnimalBar } from "../MainCotent/StateWiseAnimalsBar/StateWiseAnimalBar";
import { DayNightWiseAnimalDeath, SeasonAndAnimalBar } from "../MainCotent/DayNightAnimal/DayNightWiseAnimalDeath";
import { Footer } from "../Footer/Footer";
import { useAuthState } from "../../helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { DashboardCountDataAPI } from "../../Redux/Slices/DashboardSlice/DashboardCountAPISlice/DashboardCountSlice";
import { Loading } from "../../helper/Loader/Loading";
import { HoursWiseAnimalBar } from "../MainCotent/HoursWiseBarGraph/HoursWiseAnimalDeath";
import { useSearchParams } from "react-router-dom";
import ViewDashboardButton from "./ViewDashboardButton/ViewDashboardButton";

function MainDashboard() {

  const userDetails = useAuthState();
  const { UserID, token, } = userDetails
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const Type = searchParams.get('Type')

  // ------------header DDL ------------

  const [DashboardSelected, setDashboardSelected] = useState('1')

  const handleDashboardChange = (value) => {
    setDashboardSelected(value)
  }

  const [YearValue, setYearValue] = useState(sessionStorage.getItem('yearId'))
  const [YearLabel, setYearLabel] = useState(sessionStorage.getItem('yearLable'))
  const [MonthValue, setMonthValue] = useState(null)
  const [MonthLabel, setMonthLabel] = useState(null)

  const [Countdata, setCountdata] = useState({})

  const handleGetCount = (data) => {
    let tempData = {}
    data.forEach((item) => {
      tempData[item.paramName] = item.totalCount
    })
    setCountdata(tempData)
  }

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearValue ? YearValue : 0,
      M_MonthID: MonthValue ? MonthValue : 0,
      M_StateID: 0,
      M_DistrictNameID:0,
      Flag: "AnimalCount",
      M_UserID: UserID,
      token: token,
      handleGetCount: handleGetCount
    }
    if ((YearValue > 0)) {
      dispatch(DashboardCountDataAPI({ data }))
    }
  }, [YearValue, MonthValue])

  const { isCountLoading } = useSelector(state => state.DashboardCountData)

  const DataObj = {
    Countdata: Countdata,
    isCountLoading: isCountLoading,
    YearValue: YearValue,
    MonthValue: MonthValue,
  }

  const [isopen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isopen);

  };


  return (
    <>
      {/* <div className="wrapper"> */}
      {isCountLoading && <Loading />}
      <div className="backrounf bg_color_all" style={{ marginLeft: '5px' }}>
        <div className="container-scroller ">
          <Header
            setYearValue={setYearValue}
            setYearLabel={setYearLabel}
            setMonthValue={setMonthValue}
            setMonthLabel={setMonthLabel}
            toggle={toggle}
            isopen={isopen} />
          <div className="container-fluid page-body-wrapper">
            <Siderbar
              isopen={isopen}
              toggle={toggle}
              active={Type}
              DashboardSelected={DashboardSelected}
              setDashboardSelected={setDashboardSelected}
            />
            <div className="main-panel main_wrapping 
            content_wrapper_planting 
            margin_top_75  bg_red" style={{ width: isopen ? "calc(100%  - 102px )" : "", marginLeft: isopen ? "100px" : "" }}>

              <div className="content-wrapper px-1 ">
                {/* <MainCotent /> */}
                <div className="container-fluid px-4 pt-3">

                 
                  <ViewDashboardButton/>
                  {
                    isCountLoading && <Loading />
                  }

                  {
                    (Type == 'Total' || Type == "Dashboard") &&
                    <>
                      <NoOfAnimalAccident
                        DataObj={DataObj} />
                    </>
                  }

                  {/* State Wise Animal */}

                  {
                    (Type == 'StateWiseAccidentalAnimal' || Type == "Dashboard") &&
                    <>
                      <StateWiseAnimalBar
                        DataObj={DataObj} />
                    </>
                  }

                  {/* Season Wise Animal Death */}

                  {
                    (Type == 'SeasonWiseAccidentalAnimal' || Type == "Dashboard") &&
                    <>
                      <SeasonWiseAnimalBar
                        DataObj={DataObj} />
                    </>
                  }
                  {/* Day Night */}
                  {
                    (Type == 'DayNightWiseAccidentalAnimal' || Type == "Dashboard") &&
                    <>
                      <DayNightWiseAnimalDeath
                        DataObj={DataObj} />
                    </>
                  }

                  {/* 24 Hours Wise Accidental Animal */}

                  {
                    (Type == 'HoursWiseAccidentalAnimal' || Type == "Dashboard") &&
                    <>
                      <HoursWiseAnimalBar
                        DataObj={DataObj} />

                    </>
                  }


                  <Footer />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default MainDashboard;

import React from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

export default function ViewDashboardButton(props) {

    const { setLoadMoreValue, handleDashboardChange, setDashboardSelected, DashboardSelected, RoleID } = props

    const navigate = useNavigate()
    const handleRoadDashboard = () => {
        setLoadMoreValue(0)
        navigate('/MainDashboard?Type=Dashboard')
    }

    const [searchParams] = useSearchParams()
    const Type = searchParams.get('Type')


    return (
        <>
            <div className="row">
                {/* <div className="col-12 col-lg-6 pt-4 mt-2">
                    {
                        DashboardSelected == '1' ?
                            <h5 className='mt-4'>Rubber Plantation</h5>
                            :
                            <h5>Nursery Monitoring</h5>
                    }
                </div> */}
                <div className="col-12 col-lg-6 pt-1 ">
                    <h6 className='pb-2 dashboard_heading'>Dashboard</h6>
                </div>

                {
                    Type != "Dashboard" &&
                    <div className="col-12 col-lg-6 pt-1 pb-3 text-end">
                        {

                            <Link to="/MainDashboard?Type=Dashboard">
                                <span
                                    // className="year_buttons view_all_btn mx-2 text-end" 
                                    className="btn btn-outline-danger active"
                                    style={{ borderRadius: '6px', background: '#970707', borderColor: "#970707" }}>
                                    View All
                                </span>
                            </Link>
                        }
                    </div>
                }
            </div>
        </>
    )
}



import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const staticData = [
    { wardName: "Maharashtra", m_WardID: 1 },
    { wardName: "Goa", m_WardID: 2 },
    { wardName: "Manipur", m_WardID: 3 }
];

export const StateNameMultiCheckBox = (props) => {
    const { WardIDData, setWardIDData, setSelected, selected } = props;

    const options = staticData.map(i => ({
        label: i.wardName,
        value: i.m_WardID
    }));

    const handleChange = (e) => {
        if (e && e.length > 0) {
            setSelected(e);
            setWardIDData(e.map(i => i.value));
        } else {
            setSelected([]);
            setWardIDData([]);
        }
    };

    return (
        <div>
            <MultiSelect
                options={options}
                value={selected}
                onChange={(e) => handleChange(e)}
                labelledBy="State..."
            />
        </div>
    );
};


import { Bar, Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DayNightGridPopUp from "../DayNightGridPopUp";
import { DayNightWiseAnimalBarDataAPI } from "../../../../../Redux/Slices/DashboardSlice/DayNightWiseAccidentalAnimalSlice/DayNightWiseAccidentalAnimalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../../helper/Context/context";
import { ClipLoaderfn } from "../../../../../helper/BeatLoader";


Chart.plugins.register(ChartDataLabels);

export const DayNightWiseAnimalBar = (props) => {

    const { DataObj, MultiStateName,MultiDistrictName, AnimalName } = props
    const {
        Countdata,
        DashStateisCountLoadingIDData,
        YearValue,
        MonthValue
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const [BarColor, setBarColor] = useState([])
    const [BarshowPopup, setBarshowPopup] = useState(false)
    const [loading, setloading] = useState(false)
    const [LableName, setLableName] = useState({
        paramNameID: '',
        paramName: '',
        AnimalNameLabel: '',
        StateNameId: '',
    })
    const [DayNightWiseAnimalCountBarData, setDayNightWiseAnimalCountBarData] = useState([])

    const handleAddBarShow = () => {
        setBarshowPopup(!BarshowPopup)
    }

    const stateNames = DayNightWiseAnimalCountBarData.map(state => state.animalname);

    const dayNightNames = [];
    DayNightWiseAnimalCountBarData?.forEach(state => {
        state.dayDetails?.forEach(Day => {
            if (!dayNightNames.some(a => a.dayID === Day.dayID)) {
                dayNightNames.push(Day);
            }
        });
    });

    // useEffect(() => {
    //     for (var i = 0; i < DayNightWiseAnimalCountBarData.length; i++) {
    //         dynamicColors()
    //     }
    //     setBarColor(coloR)
    // }, [DayNightWiseAnimalCountBarData])

    const colors = ['#724d8c', '#af69a0',];

    const datasets = dayNightNames.map((Day, index) => ({
        label: Day.daysName,
        data: DayNightWiseAnimalCountBarData.map(state => {
            const DayNightDetail = state.dayDetails.find(detail => detail.dayID === Day.dayID);
            return DayNightDetail ? (DayNightDetail.totalCount == 0 ? null : DayNightDetail.totalCount) : null;
        }),
        backgroundColor: colors[index % colors.length],
        // backgroundColor: "#c95035",
        // borderColor: "#96ad36",
        borderWidth: 1.5,
        maxBarThickness: 23,
        spanGaps: true,

    }));

    useEffect(() => {
        setloading(true)
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: MonthValue ? MonthValue : 0,
            M_StateID: MultiStateName && MultiStateName.length > 0 ? MultiStateName :  0,
            M_DistrictNameID: MultiDistrictName && MultiDistrictName.length > 0 ? MultiDistrictName : 0,
            M_AnimalID: AnimalName && AnimalName.length > 0 ? AnimalName : 0,
            Flag: "DayWiseAnimalCount",
            FromTop: 1,
            ToTop: 99999,
            M_UserID: UserID,
            token: token,
            setDayNightWiseAnimalCountBarData: setDayNightWiseAnimalCountBarData,
            setloading: setloading,

        }
        if ((YearValue > 0)) {
            dispatch(DayNightWiseAnimalBarDataAPI({ data }))
        }
    }, [YearValue, MonthValue, MultiStateName,MultiDistrictName, AnimalName])

    const { isCountLoading } = useSelector(state => state.DayNightWiseAnimalBarData)


    const hadleGetName = (Label, AnimalNameLabel) => {
        if (Label != '') {
            var item = DayNightWiseAnimalCountBarData && DayNightWiseAnimalCountBarData?.find(item => item.animalname == Label);
            var data = dayNightNames && dayNightNames?.find(data => data.daysName == AnimalNameLabel);

            // console.log("data", data.seasionID)

            setLableName({
                paramName: Label,
                paramNameID: data?.dayID,
                AnimalNameLabel: AnimalNameLabel,
                StateNameId: item.m_AnimalID
            });
            setBarshowPopup(!BarshowPopup)
        }
    }
    const IntercrpingCompBar = {
        responsive: true,
        maintainAspectRatio: false,
        skipNull: true,
        interaction: {
            mode: 'index'
        },
        layout: {
            padding: {
                top: 15,
                bottom: 0, left: 0
            },
        },
        legend: {
            display: true,

            // position: 'top',
            // align: 'start',
            // fit: {
            //     height: 10
            // }
        },

        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                rotation: -90, // Rotate the labels to be vertical
                color: 'black',
                font: {
                    // weight: 'bold'
                    size: 14
                }
            },
            labels: {
                render: 'value',
                fontColor: 'black',
                display: false

            },
            legend: {
                display: false,
            },
            outlabels: {
                color: "black",
                // text: "%l\n%v (%p)",
                stretch: 15,
                font: {
                    resizable: true,
                    minSize: 12,
                    maxSize: 16,
                }
            },
        },

        onClick: (e, elements) => {
            const chart = elements && elements[0] ? elements[0]._chart : null;
            const element = chart && chart.getElementAtEvent(e)[0];
            const dataset = element && chart.data.datasets[element._datasetIndex];
            const xLabel = chart && chart.data.labels[element._index];
            // const value = dataset.data[element._index];
            // console.log(dataset.label + " at " + xLabel + ": " + value);

            // var LabelName = this.getElementsAtEvent(e).length > 0 ? this.getElementsAtEvent(e)[0]?._model?.label : '';
            // console.log("xLabel", element && chart.data.datasets[element._datasetIndex])
            if (xLabel, dataset?.label) {
                // console.log("fghj",element)
                hadleGetName(xLabel, dataset?.label);
            }
        },

        scales: {

            xAxes: [{
                // stacked: true,
                gridLines: {
                    drawOnChartArea: false,
                    color: '#1b4b7b',
                    zeroLineColors: '#1b4b7b',
                    // borderDash : [5,5]
                },
                barThickness: 25,
                ticks: {
                    autoSkip: false,
                    // fontStyle: 'bold',
                    fontSize: '13',
                    fontColor: '#1b4b7b',
                    maxRotation: 90,
                    minRotation: 90
                }

            }],
            yAxes: [{
                // stacked: false,
                gridLines: {
                    drawOnChartArea: false,
                    color: '#1b4b7b',
                    zeroLineColors: '#1b4b7b',
                    // borderDash : [5,5]
                },
                ticks: {
                    beginAtZero: true,
                    // fontStyle: 'bold',
                    fontSize: '12',
                    fontColor: '#1b4b7b',
                    stepSize: 10,
                    callback: (value, index, values) => {
                        return ` ${value} `
                    }
                }
            }]
        }
    }

    const FirstCompdata1 = {
        labels: stateNames,
        datasets: datasets
    };

    return (
        <>



            <div className="row mt-3">

                {/* <div className="col-12  bg-white py-1  shadows rounded">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h6 className="dashboard_heading fw-bold pt-3 pb-1" >Day Night Wise Accidental Animal</h6>

                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-3 pt-3">
                                            <h6 className=" float-end">State :</h6>
                                        </div>
                                        <div className="col-9 pt-1">
                                            <DashboardStateNameMultiCheckBox
                                                MultiStateName={MultiStateName}
                                                setMultiStateName={setMultiStateName}
                                                StateSelected={StateSelected}
                                                setStateSelected={setStateSelected}
                                            // apiFlag='State'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4 pt-3">
                                            <h6 className=" float-end">Animals :</h6>
                                        </div>
                                        <div className="col-8 pt-1">
                                            <DashboardAnimalNameMultiCheckBox
                                                AnimalName={AnimalName}
                                                setAnimalName={setAnimalName}
                                                AnimalSelected={AnimalSelected}
                                                setAnimalSelected={setAnimalSelected}
                                            // apiFlag='State'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div> */}

            
                {
                    loading ?
                        <div className="row child">
                            <ClipLoaderfn customcolor='#EF4069' />
                        </div>
                        :
                        <>
                            {
                                DayNightWiseAnimalCountBarData && DayNightWiseAnimalCountBarData.length > 0 ?
                                    <div className="" style={{ overflow: 'scroll' }}>
                                        <Bar
                                            options={IntercrpingCompBar}
                                            data={FirstCompdata1}
                                            // onElementsClick={handleAddBarShow}
                                            height={'400px'}
                                            // width={'2000px'}
                                            width={DayNightWiseAnimalCountBarData && DayNightWiseAnimalCountBarData.length > 4 ? '2500px' : '1150px'}
                                            className={'cursor-pointer'}
                                        />
                                    </div>
                                    :
                                    <div className="row container" style={{ height: '40vh' }}>
                                        <div>
                                        <img src="/Static/Assets/Img/chart.png" style={{ width: '8%', marginTop: '-2%' }} />
                                            <text className="mx-3 orange">Data Not Available ..</text>
                                        </div>
                                    </div>
                            }
                        </>
                }

            </div>


            {
                BarshowPopup &&

                <DayNightGridPopUp
                    handleAddBarShow={handleAddBarShow}
                    // Title={`Average Canopy Of ${LableName} (ft)`}
                    LableName={LableName}
                    DataObj={DataObj}
                    MultiStateName={MultiStateName}


                />
            }
        </>
    )
}

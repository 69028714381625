import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet, Navigate } from "react-router-dom";
import MainDashboard from "./Components/MainDashboard/MainDashboard";
import Namita from "./Components/Master/Namita";
import { Footer } from "./Components/Footer/Footer";
import LogIn from "./Public/Login/Login";

export default function AllRoutes() {

    const [RoleID, setRoleID] = useState(sessionStorage.getItem("A454A"))

    const RequiredAuth = () => {
        let Auth = sessionStorage.getItem("A454A")
        setRoleID(sessionStorage.getItem("A454A"))
        const location = useLocation()
        if (!Auth) {
            return <Navigate to="/" state={{ from: location }} />
        }
        return <Outlet />
    }

    useEffect(() => {
        setRoleID(sessionStorage.getItem("A454A"))
    }, [RoleID])

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        exact path="/"
                        element={<LogIn />}
                    />
                    <Route element={<RequiredAuth />}>

                        <Route
                            exact path="/MainDashboard"
                            element={<MainDashboard />}
                        />

                        {/* <Route
                            exact path="/Namita"
                            element={<Namita />}
                        /> */}
                        <Route
                            exact path="/Footer"
                            element={<Footer />}
                        />
                    </Route>
                </Routes>
            </Router>

        </>
    )
}
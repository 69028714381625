

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../../helper/BaseUrl";
import { toastError } from "../../../../helper/AlertMessage/AlertMessage";


// Get Table Data
export const DashboardCountDataAPI = createAsyncThunk("DashboardCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_UserID,
        Flag,
        handleGetCount,
        setStateWiseAnimalCountBarData,
        
        token
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
   
    return fetch(`${BaseUrl}/DashBoard/Get_Web_DB_MainCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_UserID=${M_UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data || result.data_StateWiseAnimal) {
                    if (Flag == "StateCount") {
                        setStateWiseAnimalCountBarData(result.data_StateWiseAnimal)
                      
                    } else {
                        handleGetCount(result.data)
                    }
                    // console.log(result.data)

                    return result.data
                } else {
                    return result
                }
            } else {
                // toastError(result.message)
                return result
            }
        })
})

const DashboardCountDataSlice = createSlice({
    name: "DashboardCountData",
    initialState: {
        isCountLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DashboardCountDataAPI.pending, (state, action) => {
            state.isCountLoading = true;
        });
        builder.addCase(DashboardCountDataAPI.fulfilled, (state, action) => {
            state.isCountLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DashboardCountDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isCountLoading = false;
            state.isError = true;
            state.tableData = null;
            toastError('Something went wrong')
        });
    },
})

export const DashboardCountDataReducer = DashboardCountDataSlice.reducer

// Get Table Data
export const NoOfAnimalsardDataAPI = createAsyncThunk("NoOfAnimalsardData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        PhotoPath,
        Flag,
        M_UserID,
        FromTop,
        ToTop,
        setloading,
        token,
        handleGetExportData,
        handleConvertData,
        setNoOfAnimal,
        setStateWiseAnimal
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    setloading && setloading(true)

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_WEB_DB_RoadKiller_DetailsSelect?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&PhotoPath=${PhotoPath}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data.table) {
                    setloading && setloading(false)
                    if (Flag == 'NoOfAnimal') {
                        handleGetExportData && handleGetExportData()
                        setNoOfAnimal && setNoOfAnimal(result.data.table)
                    } if (Flag == "StateWiseAnimal") {
                        handleGetExportData && handleGetExportData()
                        setStateWiseAnimal && setStateWiseAnimal(result.data.table)
                    }
                    // handleConvertData && handleConvertData(result.data.table)
                    // handleGetExportData && handleGetExportData()
                    return result.data.table

                } else {
                    setloading(false)
                    return result
                }
            } else {
                // toastError(result.message)
                setloading(false)
                if (Flag == 'NoOfAnimal') {
                    setNoOfAnimal && setNoOfAnimal([])
                } if (Flag == "StateWiseAnimal") {
                    setStateWiseAnimal && setStateWiseAnimal([])
                }
                return result
            }
        })
})

const NoOfAnimalsardDataSlice = createSlice({
    name: "NoOfAnimalsardData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NoOfAnimalsardDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NoOfAnimalsardDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NoOfAnimalsardDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastError('Something went wrong')
        });
    },
})

export const NoOfAnimalsardDataReducer = NoOfAnimalsardDataSlice.reducer

// Get Table Data
export const NoOfAnimalsAccidentalExportDataAPI = createAsyncThunk("NoOfAnimalsAccidentalExportData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        PhotoPath,
        Flag,
        M_UserID,
        FromTop,
        ToTop,
        setloading,
        token,
        handleGetExportData,
        handleConvertData,
        setNoOfAnimal,
        setStateWiseAnimal
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    setloading && setloading(true)

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_WEB_DB_RoadKiller_DetailsSelect?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&PhotoPath=${PhotoPath}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data.table) {
                    setloading && setloading(false)
                    if (Flag == 'NoOfAnimal') {
                        setNoOfAnimal && setNoOfAnimal(result.data.table)
                    } if (Flag == "StateWiseAnimal") {
                        setStateWiseAnimal && setStateWiseAnimal(result.data.table)
                    }
                    // handleConvertData && handleConvertData(result.data.table)
                    // handleGetExportData && handleGetExportData()
                    return result.data.table

                } else {
                    setloading(false)
                    return result
                }
            } else {
                // toastError(result.message)
                setloading(false)
                return result
            }
        })
})

const NoOfAnimalsAccidentalExportDataSlice = createSlice({
    name: "NoOfAnimalsAccidentalExportData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NoOfAnimalsAccidentalExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(NoOfAnimalsAccidentalExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(NoOfAnimalsAccidentalExportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            // toastError('Something went wrong')
        });
    },
})

export const NoOfAnimalsAccidentalExportDataReducer = NoOfAnimalsAccidentalExportDataSlice.reducer


// Get Graph Data
export const StateWisePieChartAPI = createAsyncThunk("StateWisePieChart", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        M_UserID,
        Flag,
        token,
        handleGetPiedata
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_Web_DB_AnimalCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data && result.data.table) {
                    handleGetPiedata(result.data.table)
                    return result.data.table
                } else {
                    return result
                }
            } else {
                return result
            }
        })
        .catch(err => {
            // console.log({ 'error': err });
        });
})

const StateWisePieChartSlice = createSlice({
    name: "StateWisePieChart",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(StateWisePieChartAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(StateWisePieChartAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(StateWisePieChartAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastError('Something went wrong')
        });
    },
})

export const StateWisePieChartReducer = StateWisePieChartSlice.reducer

// -----------------Group Map -------------------

export const GroupMapViewAPI = ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        PhotoPath,
        Flag,
        M_UserID,
        FromTop,
        ToTop,
        setloading,
        settableData,
        token,
        handleGetExportData,
        handleConvertData
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    setloading(true)
    fetch(`${BaseUrl}/DashBoard/Get_WEB_DB_RoadKiller_DetailsSelect?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&PhotoPath=${PhotoPath}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data.table) {
                    setloading && setloading(false)
                    settableData(result.data.table)
                    handleConvertData && handleConvertData(result.data.table)
                    // handleGetExportData && handleGetExportData()
                    return result.data.table

                } else {
                    setloading(false)
                    return result
                }
            } else {
                // toastError(result.message)
                setloading(false)
                return result
            }
        })
        .catch(error => console.log('error', error));
}

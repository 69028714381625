import React, { useState } from 'react'
import AllRoutes from './AllRoutes'
import { AuthProvider } from './helper/Context/context'
import { Provider } from 'react-redux'
import store from './Redux/store'
import { Toaster } from 'react-hot-toast'

const App = () => {
  
  return (
    <>
      <Toaster
        position='top-center'
        toastOptions={{
          success: {
            style: {
              background: 'green',
              color: 'white',
              fontSize: "18px"
            },
          },
          error: {
            style: {
              background: 'red',
              color: 'white',
              fontSize: "18px"
            },
          },
        }}
      />
      <Provider store={store}>
        <AuthProvider>       
            <AllRoutes />
        </AuthProvider>
      </Provider>

    </>
  )
}

export default App
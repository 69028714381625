import { DashboardCountDataReducer, NoOfAnimalsacccideantalExportDataReducer, NoOfAnimalsAccidentalExportDataReducer, NoOfAnimalsardDataReducer, StateWisePieChartReducer } from "./Slices/DashboardSlice/DashboardCountAPISlice/DashboardCountSlice";
import { MultiAnimalNameDDLReducer, MultiDistrictNameDDLReducer, MultiStateNameDDLReducer, StateNameDDLReducer, YearDDLReducer } from "./Slices/DDLSlice/DDLSlice";
import { seasonWiseAnimalBarDataReducer, seasonWiseAnimalDataReducer, seasonWiseAnimalExportDataReducer } from "./Slices/DashboardSlice/SeasonWiseAnimalDeathSlice/SeasonWiseAnimalDeathSlice";
import { DayNightWiseAnimalBarDataReducer } from "./Slices/DashboardSlice/DayNightWiseAccidentalAnimalSlice/DayNightWiseAccidentalAnimalSlice";
import { ShiftWiseAnimalBarDataReducer } from "./Slices/DashboardSlice/ShiftWiseAccidentalAnimalSlice/ShiftWiseAnimalSlice";

export const rootreducer = {

    DashboardCountData: DashboardCountDataReducer,
    StateNameDDLData: StateNameDDLReducer,
    YearDDLData: YearDDLReducer,
    NoOfAnimalsardData: NoOfAnimalsardDataReducer,

    MultiStateNameDDLData: MultiStateNameDDLReducer,
    MultiAnimalNameDDLData: MultiAnimalNameDDLReducer,
    seasonWiseAnimalBarData: seasonWiseAnimalBarDataReducer,
    seasonWiseAnimalData:seasonWiseAnimalDataReducer,
    DayNightWiseAnimalBarData:DayNightWiseAnimalBarDataReducer,
    ShiftWiseAnimalBarData:ShiftWiseAnimalBarDataReducer,
    StateWisePieChartData:StateWisePieChartReducer,

    NoOfAnimalsAccidentalExportData:NoOfAnimalsAccidentalExportDataReducer,
    seasonWiseAnimalExportData:seasonWiseAnimalExportDataReducer,
    MultiDistrictNameDDLData:MultiDistrictNameDDLReducer,


}

import { useEffect, useState } from "react";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../helper/Context/context";
import { DashboardCountDataAPI, StateWisePieChartAPI } from "../../../Redux/Slices/DashboardSlice/DashboardCountAPISlice/DashboardCountSlice";
import StateWiseAnimalBarPopUp from "./Modal/StateWiseAnimalBarPopUp";
import { DashboardStateNameMultiCheckBox } from "../../CommonDDL/DashboardMultiCheckStateNameDDL";
import { DashboardAnimalNameMultiCheckBox } from "../../CommonDDL/DashBoardMultiCheckAnimalNameDDL";
import { StateWiseBar } from "./Bar/StateWiseBar";
import Chart from "react-google-charts";
import { BeatLoaderfn, ClipLoaderfn } from "../../../helper/BeatLoader";
import { DashboardDistrictNameMultiCheckBox } from "../../CommonDDL/DashboardMultiCheckDistrictNameDDL";
import { StateNameDDLData } from "../../CommonDDL/StateNameDDLData";
import { DistrictNameDDLData } from "../../CommonDDL/DistrictNameDDLData";


export const StateWiseAnimalBar = (props) => {

    const { DataObj } = props
    const {
        Countdata,
        DashStateisCountLoadingIDData,
        YearValue,
        MonthValue
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const [BarColor, setBarColor] = useState([])

    // ########### Start Multi Checkbox ##############

    const [YearSelected, setYearSelected] = useState('0')

    const [MultiStateName, setMultiStateName] = useState([]);
    const [StateSelected, setStateSelected] = useState([]);
    const [barColors, setBarColors] = useState([]);

    const handleMultiStateData = (e) => {
        if (e && e.length > 0) {
            setStateSelected(e)
            setMultiStateName(e.map(i => i.value))
            // setMultiStateName(e.map(i => console.log("typeof",typeof(i.value))))

        } else {
            setStateSelected([])
            setMultiStateName([])
            setDistrictSelected([])
            setMultiDistrictName([])
        }
    }
    // ########### End Multi State ##############

    // ########### District Multi Checkbox ##############

    const [MultiDistrictName, setMultiDistrictName] = useState([]);
    const [DistrictSelected, setDistrictSelected] = useState([]);
    const [loader, setloader] = useState(false);


    const handleMultiDistrictData = (e) => {
        if (e && e.length > 0) {

            setDistrictSelected(e)
            setMultiDistrictName(e.map(i => i.value))
        } else {
            setDistrictSelected([])
            setMultiDistrictName([])
        }
    }
    // ########### End Multi District ##############

    // ------------------------------------------------

    const [PieData, setPieData] = useState([["State", "Value"]])

    const [StateNameID, setStateNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [DistrictNameID, setDistrictNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    useEffect(() => {
        setloader(true)
        setPieData([])
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: 0,
            M_StateID: StateNameID ? StateNameID.ID : 0,
            M_DistrictNameID: DistrictNameID ? DistrictNameID.ID : 0,
            M_AnimalID: 0,
            M_UserID: UserID,
            Flag: "AnimalChartCount",
            token: token,
            handleGetPiedata: handleGetPiedata,
        }
        dispatch(StateWisePieChartAPI({ data }))
    }, [YearValue, StateNameID.ID, DistrictNameID.ID])

    const handleGetPiedata = (Pievalue) => {
        setPieData([["State", "Value"]])
        Pievalue.map(j => {
            setPieData(oldArray => [...oldArray, [j.paramName, j.totalCount1]]);
        })
        setTimeout(() => {
            setloader(false)
        }, 500);
    }

    const { isLoading, tableData } = useSelector(state => state.StateWisePieChartData)

    const coloR = []

    var dynamicColors = function () {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        // console.log("klhhh","rgb(" + r + "," + g + "," + b + ")")
        coloR.push("rgb(" + r + "," + g + "," + b + ")")
        return "rgb(" + r + "," + g + "," + b + ")";
    };

    useEffect(() => {
        const colors = generateRandomColors(PieData);
        setBarColors(colors);
    }, [PieData]);

    const generateRandomColors = (PieData) => {
        const colors = [];
        for (let i = 0; i < PieData.length; i++) {
            colors.push(dynamicColors());
        }
        return colors;
    };

    const total = PieData.slice(1).reduce((sum, [, totalCount1]) => sum + totalCount1, 0);

    const formattedData = PieData.map((row, index) => {
        if (index === 0) return row;
        const [category, totalCount1] = row;
        // const percentage = ((totalCount1 / total) * 100).toFixed(2); // Format percentage to two decimal places
        const percentage = totalCount1.toFixed(2);

        return [`${category}: ${percentage}%`, totalCount1];
    });

    const optionsnew = {
        pieSliceText: "label",
        is3D: true,
        // legend: {
        //     position:'none'
        // },
        legend: {
            position: 'right',
        },
        tooltip: {
            trigger: 'focus',
            text: 'none'
        },
        // pieSliceText: 'value',
        chartArea: {
            height: "100%",
            width: "100%"
        },
        colors: barColors
    };

    // const optionsnew = {
    //     title: "label",
    //     is3D: true,
    //     legend: {
    //         position: 'top',
    //     },
    //     tooltip: {
    //         trigger: 'focus',
    //         text: 'none'
    //     },
    //     chartArea: {
    //         height: "94%",
    //         width: "94%"
    //     },
    //     colors: barColors
    // };

    return (
        <>
            <div className="row pt-3 rounded">
                {/* <h6 className="dashboard_heading fw-bold py-3 bg-white shadows rounded" >State Wise Accidental Animal</h6> */}
                <div className="col-12 bg-white py-1 shadows rounded">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h6 className="dashboard_heading fw-bold pt-3 pb-1" >State Wise Accidental Animal</h6>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-11 col-lg-6">
                                    <div className="row">
                                        <div className="col-4 col-lg-4 pt-3">
                                            <h6 className=" float-end">State</h6>
                                        </div>
                                        <div className="col-8 pt-1">
                                            <StateNameDDLData
                                                StateNameID={StateNameID}
                                                setStateNameID={setStateNameID}
                                                apiFlag='State'
                                                Flag='Insert'
                                                data=''
                                                IsPost=''
                                                isdefault={true}
                                            />

                                            {/* <DashboardStateNameMultiCheckBox
                                                handleMultiStateData={handleMultiStateData}
                                                MultiStateName={MultiStateName}
                                                setMultiStateName={setMultiStateName}
                                                StateSelected={StateSelected}
                                                setStateSelected={setStateSelected}
                                            // apiFlag='State'
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 col-lg-6">
                                    <div className="row">
                                        <div className="col-4 col-lg-4 pt-3">
                                            <h6 className=" float-end">District</h6>
                                        </div>
                                        <div className="col-8 pt-1">
                                            <DistrictNameDDLData
                                                DistrictNameID={DistrictNameID}
                                                setDistrictNameID={setDistrictNameID}
                                                apiFlag='District'
                                                Flag='Insert'
                                                data=''
                                                IsPost=''
                                                isdefault={true}
                                                StateNameID={StateNameID}
                                            />

                                            {/* <DashboardDistrictNameMultiCheckBox
                                                handleMultiDistrictData={handleMultiDistrictData}
                                                MultiDistrictName={MultiDistrictName}
                                                setMultiDistrictName={setMultiDistrictName}
                                                DistrictSelected={DistrictSelected}
                                                setDistrictSelected={setDistrictSelected}
                                                MultiStateName={MultiStateName}
                                            // apiFlag='State'
                                            /> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row shadows rounded m-0 pt-4 my-2 pb-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-4 pt-2 m-0 pb-4 border-end">
                            {
                                loader ? (
                                    <div className="row child">
                                        <BeatLoaderfn customcolor='#EF4069' />
                                    </div>
                                ) : (
                                    PieData.length > 1 && (
                                        <Chart
                                            chartType="PieChart"
                                            data={formattedData}
                                            options={optionsnew}
                                            height={300}

                                        // loader={<div>Loading Chart...</div>}
                                        />
                                    )
                                )}
                        </div>

                        <StateWiseBar
                            DataObj={DataObj}
                            MultiStateName={StateNameID.ID}
                            MultiDistrictName={DistrictNameID.ID}
                            barColors={barColors}
                        />

                    </div>
                </div>
            </div >
        </>
    )
}

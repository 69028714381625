import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { useAuthState } from '../../helper/Context/context';
import { YearDDLAPI } from '../../Redux/Slices/DDLSlice/DDLSlice';


export const YearDDL = (props) => {
    const { FinancialYear, setFinancialYear, setYearValue, Flag, data, Disabled } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const d = new Date();
    let year = d.getFullYear();
    // console.log(year)


    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
        }
        dispatch(YearDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.YearDDLData)

    useEffect(() => {
        handleYearDDL()
    }, [DDLData])

    const handleYearDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {
            let list = DDLData.table.map((item, index) => ({
                value: item.id,
                label: item.financialYear,
            }))

            setFinancialYear({
                DDL: list,
                // ID: Flag == 'Update' ? data?.id : 0,
                // Label: Flag == 'Update' ? data?.financialYear : "Select Year...",
                ID: sessionStorage.getItem('yearId') ? sessionStorage.getItem('yearId') : 0,
                Label: sessionStorage.getItem('yearLable') ? sessionStorage.getItem('yearLable') : 'Select Year ...',
            })
        }
        else {
            setFinancialYear({
                DDL: [],
                ID: 0,
                Label: "Select Year ...",
            })
        }
    }

    const handleChangeYear = (e) => {
        setFinancialYear({ ...FinancialYear, ID: e.value, Label: e.label })
        setYearValue(e.value)
        sessionStorage.setItem("yearId", e.value)
        sessionStorage.setItem("yearLable", e.label)
    }

    const handleClearYear = (e) => {
        setFinancialYear({ ...FinancialYear, ID: 0, Label: "Select Year ..." })
        sessionStorage.setItem("yearId", '0')
        sessionStorage.setItem("yearLable", 'Select Year ...')
        setYearValue(0)
    }

    return (
        <div className="form-group">
            <Select
                isClearable
                // isRtl={isRtl}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        //   borderColor: state.isFocused ? 'grey' : 'red',
                        borderColor: state.isFocused ? 'transparent' : 'transparent',
                        borderColor: '#cccccc',
                        boxShadow: 'none',
                        // background:"black",
                        // color:"white"
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    })
                }}
                components={{
                    IndicatorSeparator: () => null
                }}
                isSearchable
                maxMenuHeight={150}
                value={{ value: FinancialYear.ID, label: FinancialYear.Label }}
                onChange={(e) => {
                    e ?
                        handleChangeYear(e)

                        :
                        handleClearYear(e)

                }}
                options={FinancialYear.DDL}
            />
        </div>
    )
}
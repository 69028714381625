
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { StateNameDDLAPI } from '../../Redux/Slices/DDLSlice/DDLSlice'
import { useAuthState } from '../../helper/Context/context'

export const AnimalNameDDLData = (props) => {

    const { AnimalNameID, setAnimalNameID, Flag, data, IsPost, apiFlag, AstricSignFlag, LableChangeFlag, StateNameID, DistrictNameID } = props
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const d = new Date();
    let month = d.getMonth() + 1;
    // var monthMinusOneName = moment().format('MMMM');
    let year = d.getFullYear();
    // var YearLables = moment().format('YYYY');

    var MonthSession = sessionStorage.getItem("monthId")
    var YearSession = sessionStorage.getItem("yearId")

    const [AnimalData, setAnimalData] = useState([])


    useEffect(() => {
        const data = {
            Flag: apiFlag,
            M_MonthID: 0,
            M_FinancialYearID: 0,
            M_StateID: StateNameID.ID,
            M_DistrictNameID: DistrictNameID.ID ? DistrictNameID.ID : 0,
            M_AnimalID: 0,
            M_UserID: UserID,
            Top: "99999",
            token: token,
            setAnimalData: setAnimalData
        }
        dispatch(StateNameDDLAPI({ data }))
    }, [IsPost, StateNameID.ID, DistrictNameID.ID])

    const { DDLData } = useSelector(state => state.StateNameDDLData)

    useEffect(() => {
        handleDDL()
    }, [AnimalData])

    const handleDDL = () => {
        if (AnimalData && AnimalData.length > 0) {
            // console.log("DDLData.table", DDLData.table)
            let list = AnimalData.map((item, index) => ({
                value: item.m_AnimalID,
                label: item.animalName,
            }))

            setAnimalNameID({
                DDL: list,
                ID: Flag == 'Update' ? data?.m_AnimalNameID : 0,
                Label: Flag == 'Update' ? data?.AnimalName : "Select...",
            })
        }

        else {
            setAnimalNameID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <h6 className="d-block">Animals </h6>
            <Select
                id='AnimalNameID'
                isClearable={false}
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={130}
                value={{ value: AnimalNameID.ID, label: AnimalNameID.Label }}
                onChange={(e) => {
                    e ?
                        setAnimalNameID({ ...AnimalNameID, ID: e.value, Label: e.label })
                        :
                        setAnimalNameID({ ...AnimalNameID, ID: 0, Label: "Select..." })

                }}
                options={AnimalNameID.DDL}
            />
        </div>
    )
}

import toast from "react-hot-toast"


export const toastSuccess = (message) => {
  toast.success(message, {
    backgroundColor: 'green',
    color: '#ffffff',
    duration: 3000
  })
}

export const toastError = (message) => {
  toast.error(message, {
    backgroundColor: 'red',
    color: '#ffffff',
    duration: 3000
  })
}

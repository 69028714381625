import { useDispatch } from "react-redux"
import AddPopupNoOfAnimals from "./Modal/AddPopupNoOfAnimals"
import { useEffect, useState } from "react"
import { useAuthState } from "../../../helper/Context/context"
import { toastError } from "../../../helper/AlertMessage/AlertMessage"
import { GroupMapViewAPI } from "../../../Redux/Slices/DashboardSlice/DashboardCountAPISlice/DashboardCountSlice"
import { GoogleMapView } from "../MapView/GoogleMapView"


export const NoOfAnimalAccident = (props) => {

    const { DataObj } = props
    const {
        Countdata,
        DashStateisCountLoadingIDData,
        YearValue,
        MonthValue
    } = DataObj

    // console.log("DataObj", DataObj)
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const [tableData, settableData] = useState([])
    const [loader, setloader] = useState(false)
    const [loading, setloading] = useState(false)
    const [ConversionData, setConversionData] = useState()
    // Map
    const [addPopUpNoOfAnimals, setaddPopUpNoOfAnimals] = useState(false)

    const handleNoOfAnimals = () => {
        setaddPopUpNoOfAnimals(false)
    }

    // Map
    const [AllGroupTreeMap, setAllGroupTreeMap] = useState({
        show: false,
        data: ''
    })
    const handleAllGroupTreeMapClose = () => {
        setAllGroupTreeMap({
            show: false,
            data: ''
        })
    }

    let AllTreeDataMap = [];
    var latlongObj = {}
    var locationArray = []

    let ConvData = []
    let showError = (item => item.assetLatitude === null && item.assetLongitude === null) ? true : false;
    let showMessage = false

    useEffect(() => {
        const data = {

            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: 0,
            M_StateID: 0,
            M_DistrictNameID:0,
            M_AnimalID: 0,
            PhotoPath: '',
            M_UserID: UserID,
            Flag: "GoTagging",
            FromTop: '0',
            ToTop: "99999",
            token: token,
            settableData: settableData,
            setloading: setloading,
            handleConvertData: handleConvertData
        }
        GroupMapViewAPI({ data })
    }, [YearValue, MonthValue])



    const handleAllTreeLocation = (data) => {
        setloader(true);
        if (data) {
            data.forEach(item => {
                if (item.latitude !== null && item.longitude !== null) {
                    AllTreeDataMap = [...AllTreeDataMap, item];
                    // showError=false
                    setTimeout(() => {
                        setAllGroupTreeMap({
                            show: true,
                            data: AllTreeDataMap,
                        });
                        if (!showMessage) {
                            showMessage = true
                        }
                    }, 1000);
                } else {
                    if (!showError) {
                        toastError('Location Data not available')
                        showError = true;
                    }
                }
            });
        }
        setTimeout(() => {
            setloader(false);
        }, 1000);
    }


    const handleConvertData = (data) => {
        data.forEach(function (e, i) {
            if (e.latitude !== null && e.longitude !== null) {
                ParseDMSLat(data && data[i].latitude, data && data[i].longitude, data)
            }
        });
    }

    function ParseDMSLat(lat, long, Data) {
        // console.log(lat, long, Data)
        var partslat = lat.split(/[^\d\w\.]+/);
        var partslong = long.split(/[^\d\w\.]+/);
        ConvertDMSToDDLat(partslat[0], partslat[1], partslat[2], partslat[3], partslong[0], partslong[1], partslong[2], partslong[3], Data);
    }

    function ConvertDMSToDDLat(degreesLat, minutesLat, secondsLat, directionLat, degreesLong, minutesLong, secondsLong, directionLong, Data) {
        var ddLat = Number(degreesLat) + Number(minutesLat) / 60 + Number(secondsLat) / (60 * 60);
        var ddLong = Number(degreesLong) + Number(minutesLong) / 60 + Number(secondsLong) / (60 * 60);

        if (directionLat == "S" || directionLat == "W") {
            ddLat = ddLat * -1;
        } // Don't do anything for N or E

        if (directionLong == "S" || directionLong == "W") {
            ddLong = ddLong * -1;
        } // Don't do anything for N or E
        latlongObj = { 'latitude': ddLat, 'longitude': ddLong }
        locationArray.push(latlongObj)
        ConvData = Data?.map((item) => ({ ...item, latitude: ddLat, longitude: ddLong }))
        setConversionData(locationArray)
        // console.log(latlongObj)
    }

    // ################### Conversion ###################



    return (
        <>
            <div className="row rounded py-3  shadows" style={{ background: '#ffffff', }}>

                {/* <h6 className="text-black fw-bold pb-2">Upcomming Events</h6> */}

                <div className="col-12 col-lg-12 ">
                    <div className="row second_div shadow "
                       >
                        <div className="col-12 col-lg-6 cursor-pointer"  onClick={() => {
                            setaddPopUpNoOfAnimals(true)
                        }} >
                            <h5 style={{ fontSize: '19px' }}>No. Animals Accident on Road

                            </h5>
                            <h2 className="fw-bold pt-2" style={{ color: '#08497a' }}>{Countdata && Countdata.NoofAnimalsAccidentCount ? Countdata.NoofAnimalsAccidentCount : '0'}</h2>
                        </div>
                        <div className="col-4 col-lg-3 d-flex align-items-center">
                            {
                                (loading) ?
                                    <div className="col-2 rounded border border-success text-center ms-4 bg-white  cursor-pointer"
                                        style={{ height: '40px' }}
                                    >
                                        <h5 className="py-2 ">
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                            /></h5>
                                    </div>
                                    :
                                    <div className="col-5 col-lg-2"
                                        onClick={() => {
                                            handleAllTreeLocation(tableData && tableData)
                                            // navigate('/GoogleMapView')
                                        }}
                                    >
                                        <div className="rounded border border-success text-center bg-white cursor-pointer Map_View_Button"
                                            style={{ height: '2.5em', width: '6em' }}>
                                            <h5 className="py-2 mx-1 " >
                                                <img src="/Static/Assets/Img/google-maps.png" style={{ width: '30%' }} />
                                                {/* <i class="fa-solid fa-location-dot "></i>  */}
                                                <span style={{ fontSize: '19px' }}>
                                                    Map
                                                </span>

                                            </h5>
                                        </div>
                                    </div>
                            }

                        </div>
                        <div className="col-7 col-lg-3  d-flex  justify-content-center align-items-center">
                            <img src="/Static/Assets/Img/Animals.png" alt="" style={{ width: '60px' }} />
                        </div>

                    </div>

                </div>

                {/* <div className="col-12 col-lg-9 ">
                    <div className="row second_div py-2 px-0" >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6238.546235321379!2d73.824464!3d18.460048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc295e613b54b99%3A0x466d7a51d1b0faef!2sCDAT%20PUNE!5e1!3m2!1sen!2sin!4v1719050152451!5m2!1sen!2sin" style={{ width: '80vw', height: '145px' }} referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>

                </div> */}

                {addPopUpNoOfAnimals ?
                    <AddPopupNoOfAnimals
                        cancelbtnphoto={handleNoOfAnimals}
                        heading="No. Animals Accident on Road"
                        DataObj={DataObj} />
                    : <></>
                }

                {AllGroupTreeMap.show ?
                    <GoogleMapView
                        cancelbtnphoto={handleAllGroupTreeMapClose}
                        heading="No. of Animals Maped : "
                        AllGroupTreeMap={AllGroupTreeMap}
                        ConversionData={ConversionData}
                        Countdata={Countdata}
                    // PlanationDetailsCountdata={PlanationDetailsCountdata}
                    />
                    :
                    <>
                    </>
                }


            </div>
        </>
    )
}
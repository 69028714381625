import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Login.css'
import { useAuthDispatch, useAuthState } from "../../helper/Context/context";
// import useEnterKeyListener from "../../../helper/useEnterKeyListener";
import { loginUser } from "../../helper/Context/action";
import useEnterKeyListener from "../../helper/useEnterKeyListener";
// import { useAuthDispatch, useAuthState } from "../../../helper/Context/context";

export default function LogIn() {

    const navigate = useNavigate()

    const dispatch = useAuthDispatch();
    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails

    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    const [loading, setloading] = useState(false)
    const [Validationerror, setValidationerror] = useState(false)
    const [Loginmessage, setLoginmessage] = useState('')


    const handleLogin = async (e) => {
        setLoginmessage('')
        // e.preventDefault()
        if (userName !== '' && password !== '') {
            try {
                let response = await loginUser(dispatch, { userName, password, setloading, handleRollIdWiseDashboard, handleErrorIdWiseDashboard, setLoginmessage });
                if (!response) return;

            } catch (error) {
                console.log(error);
            }
            setValidationerror("")
        } else {
            setValidationerror("Error")
        }

    }

    const handleRollIdWiseDashboard = (data) => {
        if (data.RoleID) {
            navigate(`/MainDashboard?Type=Dashboard`)
        }
    }

    const handleErrorIdWiseDashboard = (data) => {
        setLoginmessage(data)
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        //console.log("X", y)
        if (x.type === "password") {

            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fas fa-eye") {
            y.className = "fas fa-eye-slash";
        } else {
            y.className = "fas fa-eye";
        }

    }

    const showPassEncrypt = () => {
        var x = document.getElementById("pass");
        x.type = "password";
    }

    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
    });

    return (
        <>


            <div style={{
                height: '100vh',
                fontFamily: 'Poppins'
            }}>

                <div className=" row d-flex shadow  nmmc_blue_bg align-items-center mx-auto   mobile_none " style={{ height: '12vh' }} >

                    <div className="col-8 col-md-12 px-3  ps-5" style={{ textAlign: "start" }}>
                        
                        <img src="./static/assets/Img/logo-01.png" alt="" srcset="" style={{ width: '60px',background:'white' }} className="rounded" />
                      
                        <span className="headingcolor   text-white ps-5" style={{
                            alignSelf: "center", fontSize: '25px', fontWeight: '500'
                        }}>
                            Van Path सु-Rakshak  <span className="fs-5"> ( Web and Mobile Based ) </span></span>
                    </div>

                </div>

                <div className='row px-2 ' style={{ height: '78vh' }}>

                    <div className="col-12 col-md-7 col-lg-8 px-5 wardha_background mx-auto   d-none d-lg-block">
                    </div>

                    <div className="col-12 col-md-5 mt-1 mt-md-0 col-lg-4 shadow mx-auto  " style={{ display: 'flex', justifyContent: "center", alignItems: "center", background: '#d5dbe2' }}>
                        <div className='row g-0' >

                            <div className="text-center">
                                <p className=" pb-0 mb-0"> <img src="./static/assets/Img/road_killer_logo_trans-01.png" alt="" srcset=""
                                    className="logo_in_circle" /></p>

                                <h5 className="pb-md-0 pb-lg-2 fw-bold pb-0 pt-2"
                                >Van Path सु-Rakshak</h5>
                            </div>
                            <div className='col-9 mx-auto'>
                                <form action="#" method="post">

                                    <div className="input-group mb-3">
                                        <span className="input-group-text border-radius-left" id="basic-addon1">
                                            <i className="fa-solid fa-user" style={{ color: '#3e5b7e' }}></i></span>
                                        <input type="text"
                                            className="form-control f7"
                                            placeholder="Username"
                                            aria-describedby="basic-addon1"
                                            value={userName}
                                            onChange={(e) => setuserName(e.target.value)} />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-lock" style={{ color: '#3e5b7e' }}></i></span>
                                        <input
                                            type="text"
                                            id="pass"
                                            name="psw"
                                            className="form-control f7"
                                            placeholder="Password"
                                            aria-describedby="basic-addon1"
                                            value={password}
                                            onChange={(e) => {
                                                setpassword(e.target.value)
                                                showPassEncrypt()
                                            }}
                                        />
                                        <span className="input-group-text" id="basic-addon1">
                                            <i
                                                onClick={() => showPass()}
                                                id="eye"
                                                className="fas fa-eye"
                                                // className="fa-solid fa-eye"
                                                style={{ color: '#3e5b7e' }}></i>
                                        </span>
                                    </div>


                                    <span style={{ textDecoration: 'none' }} >
                                        <button
                                            onClick={handleLogin}
                                            type="button"
                                            value="Login"
                                            disabled={userName == '' || password == ''}
                                            className="btn btn-block btn_submit form-control fw-bold" >
                                            {
                                                loading &&
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            }
                                            Login
                                        </button>
                                    </span>
                                    <div className="my-3 fs-7 ">
                                        <a className="text-danger text-center"> {Loginmessage}</a>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 text-center pb-1 pt-4  pt-lg-3" style={{ alignSelf: 'center' }}>

                                <div className='mb-1'>
                                    <h6 className='text-dark f7'> <i className="fa-solid fa-circle-info "></i> Help Desk</h6>
                                </div>

                                <div className="">
                                    <h6 className='text-danger f7'>Customer Support</h6>
                                </div>

                                <div className='pt-1 '>
                                    <h6 className="text-dark f7"><i className="fa-solid fa-phone  pe-1"></i>

                                        <a
                                            href="tel:91726 50917" /><span className="text-dark ml-2">  +91 91726 50917</span> </h6>
                                </div>

                                <div className='pt-1'>
                                    <h6 className="text-dark pb-2 f7"><i className="fa-solid fa-envelope  pe-1"></i>

                                        <a
                                            href="mailto:info@cdat.co.in" /><span className="text-dark ml-2">  info@cdat.co.in</span> </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row g-0'>
                    <div className='col-12 px-3 px-lg-0 text-center  mt-md-0 d-flex align-items-center justify-content-center  nmmc_blue_bg py-4' >
                        <span className="text-white text-center " style={{}}
                        >
                            &copy;

                            <span className="silver text-white ">{new Date().getFullYear()} Copyright Van Path सु-Rakshak. All Rights Reserved.
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

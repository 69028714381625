

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'
import React from "react"

export const HourseWiseBarExportData = (props) => {
    const { ExcelData, name, searchName, heading, LableName } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-HourseExport"
                className=" export_btn me-5 download-table-xls-button me-1 btn btn-export btn-md mr-1 mb-1 ml-1 "
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'gray' }}
            />

            <table id="table-to-HourseExport" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>
                
                    <h5 colSpan={15} style={{ paddingLeft: '50px', fontSize: 16,textAlign: "center"}}>{`${name}`}</h5>
                
                <thead>
                    <tr style={{ backgroundColor: '#254462', color: "#fff", borderTop: "1px solid gray" }}>

                        <th className="w-6 px-3" style={{ whiteSpace: 'nowrap' }}>Sr. No.</th>
                        <th>Animal Name</th>
                        <th>State</th>
                        <th>District</th>
                        <th>Block</th> 
                        <th>Season</th>                   
                        <th>Latitude/Longitude</th>
                        <th>Date</th>
                        <th>Time</th>              
                        <th>Day/Night</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.length > 0 ? ExcelData.map((i, index) => {
                            return (
                                <>
                                    <tr style={{ width: '100%', border: "1px solid gray" }} key={index}>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>{i.rowNum}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.animalName ? i.animalName : '-'}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.stateName ? i.stateName : '-'}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.districtName ? i.districtName : '-'}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.blockName ? i.blockName : '-'}</td>                      
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.season ? i.season : '-'}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.latitude ? i.latitude : '-'}
                                            <br className="m-0" />
                                            {i.longitude ? i.longitude : '-'}</td>

                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.uploadDate ? moment(i.uploadDate).format('DD-MM-YYYY') : '-'}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.uploadTime ? i.uploadTime : '-'}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: 'center' }}>{i.dayNight ? i.dayNight : '-'}</td>


                                    </tr>
                                </>
                            )
                        }
                        )
                            : <td colSpan={15}>No data found</td>
                    }
                </tbody>

            </table>
        </div>
    )
}
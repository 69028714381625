import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../../helper/BaseUrl";
import { toastError } from "../../../../helper/AlertMessage/AlertMessage";


// Get Table Data
export const DayNightWiseAnimalBarDataAPI = createAsyncThunk("DayNightWiseAnimalBarData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        M_UserID,
        FromTop,
        ToTop,
        Flag,
        setloading,
        setDayNightWiseAnimalCountBarData,
        token
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_Web_DB_Animal_Wise_Day_Count_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                setloading && setloading(false)
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data_DayWiseAnimal) {
                    setDayNightWiseAnimalCountBarData(result.data_DayWiseAnimal)
                    setloading && setloading(false)
                    return result.data_DayWiseAnimal
                } else {
                    return result
                }
            } else {
                // toastError(result.message)
                return result
                setloading && setloading(false)
            }
        })
})

const DayNightWiseAnimalBarDataSlice = createSlice({
    name: "DayNightWiseAnimalBarData",
    initialState: {
        isCountLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DayNightWiseAnimalBarDataAPI.pending, (state, action) => {
            state.isCountLoading = true;
        });
        builder.addCase(DayNightWiseAnimalBarDataAPI.fulfilled, (state, action) => {
            state.isCountLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DayNightWiseAnimalBarDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isCountLoading = false;
            state.isError = true;
            state.tableData = null;
            toastError('Something went wrong')
        });
    },
})

export const DayNightWiseAnimalBarDataReducer = DayNightWiseAnimalBarDataSlice.reducer




import { APIProvider, InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import moment from 'moment';
import { useMemo, useState } from "react";
import { ApiMapKey } from '../../../helper/BaseUrl';

export const GoogleMapView = (props) => {
    const { cancelbtnphoto, AllGroupTreeMap, heading, ConversionData, Countdata, PlanationDetailsCountdata } = props

    // console.log("AllGroupTreeMap",AllGroupTreeMap.data[0].totalcount)

    // const position = { lat: AllGroupTreeMap && AllGroupTreeMap[0].treeLatitude, lng: AllGroupTreeMap && AllGroupTreeMap[0].treeLongitude };
    const position = { lat: ConversionData && ConversionData[0].latitude, lng: ConversionData && ConversionData[0].longitude };

    const [infoWindowOpen, setInfoWindowOpen] = useState({
        show: false,
        lattitude: '',
        longitude: ''
    });

    const showInfoWindow = (lat, long, data) => {
        setInfoWindowOpen({
            show: true,
            lattitude: lat,
            longitude: long,
            data: data
        });
    };

    const closeInfoWindow = () => {
        setInfoWindowOpen({
            show: false,
            lattitude: '',
            longitude: '',
            data: ''
        });
    };

    const containerStyle = {
        // width: ,
        height: '75vh'
    };

    const markers1 = [
        { lat: 18.5204, lng: 73.8567 },
        { lat: 18.5314, lng: 73.8446 },
        { lat: 18.5642, lng: 73.7769 },
    ];

    // const onLoad = (map) => {
    //   const bounds = new google.maps.LatLngBounds();
    //   markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    //   map.fitBounds(bounds);
    // };

    return (
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog modal-lg" role="document" style={{ width: '87vw', height: '82vh' }}>
                <div className="modal-content p-3">
                    <div className="modal-header pt-0">
                        {/* <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{heading}<span className='text-danger'>({Countdata && Countdata.NoOfTrees ? Countdata.NoOfTrees.toFixed(0) : '0'})</span></h5> */}
                        <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{heading}<span className='text-danger'>{AllGroupTreeMap.data[0]?.totalcount ? AllGroupTreeMap.data[0]?.totalcount : '0'}</span></h5>
                        <button onClick={() => cancelbtnphoto()} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0 p-0">

                        <div className="">

                            {/* <APIProvider apiKey={'AIzaSyCj53sWEgwzj-FtjijWXzH1ngmREzfwmSU'}> */}
                            <APIProvider apiKey={'AIzaSyBReqWPgtFbd8bY69J4UJbuWXTa4E5sDNg'}>
                                <Map defaultCenter={position} defaultZoom={15} style={containerStyle} mapTypeControl={false}>
                                    {/* {AllGroupTreeMap && AllGroupTreeMap.length > 0 && AllGroupTreeMap.map((i,j) => ( */}
                                    {AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.length > 0 && AllGroupTreeMap.data.map((i, j) => (

                                        <>
                                            <Marker
                                                position={{ lat: ConversionData[j].latitude, lng: ConversionData[j].longitude }}
                                                icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}

                                                onClick={() => showInfoWindow(ConversionData[j].latitude, ConversionData[j].longitude, i)}
                                            />
                                            {
                                                infoWindowOpen.show &&
                                                <InfoWindow
                                                    position={{ lat: infoWindowOpen.lattitude, lng: infoWindowOpen.longitude }}
                                                    headerContent={<h3 style={{ fontSize: '15px', color: 'red' }}> Animal Details</h3>}
                                                    onClose={closeInfoWindow}
                                                >

                                                    <div className='marker-popup py-2' style={{ width: '400px' }}>
                                                        <hr style={{ marginTop: '0px' }} />
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Animal Name</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.animalName ? infoWindowOpen?.data.animalName : '-'}</div>
                                                        </div>

                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>State</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.stateName ? infoWindowOpen?.data.stateName : '-'}</div>
                                                        </div>

                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>District</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.districtName ? infoWindowOpen?.data.districtName : '-'}</div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Block</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.blockName ? infoWindowOpen?.data.blockName : '-'}</div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Season</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.season ? infoWindowOpen?.data.season : '-'}</div>
                                                        </div>

                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Lattitude</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.latitude ? infoWindowOpen?.data.latitude : '-'}</div>
                                                        </div>

                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Longitude</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.longitude ? infoWindowOpen?.data.longitude : '-'}</div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Day/Night</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.dayNight ? infoWindowOpen?.data.dayNight : '-'}</div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5 fw-bold'>Shift</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            <div className='col-6 fw-bold'>{infoWindowOpen?.data.shiftName ? infoWindowOpen?.data.shiftName : '-'}</div>
                                                        </div>


                                                        <div className='row m-1'>
                                                            <div className='col-5 fw-bold'> Photo</div>
                                                            <div className='col-1 fw-bold'>:</div>
                                                            {
                                                                // infoWindowOpen?.data.animal_Photo == 'Plantation' ?
                                                                <div className='col-6 fw-bold'>
                                                                    {
                                                                        infoWindowOpen?.data?.animal_Photo ?
                                                                            <span className='cursor-pointer' onClick={() => window.open(infoWindowOpen?.data?.animal_Photo)}>
                                                                                <i className="fa-solid fa-image fs-5 "></i>
                                                                            </span>
                                                                            :
                                                                            <span>-</span>
                                                                    }
                                                                </div>
                                                                // :
                                                                // <div className='col-6 fw-bold'>
                                                                //     {
                                                                //         infoWindowOpen?.data?.afterPhoto ?
                                                                //             <>
                                                                //                 <span className='cursor-pointer' onClick={() => window.open(infoWindowOpen?.data?.afterPhoto)}>
                                                                //                     <i className="fa-solid fa-image fs-5 "></i>
                                                                //                 </span>
                                                                //             </>
                                                                //             :
                                                                //             <span>-</span>
                                                                //     }
                                                                // </div>
                                                            }

                                                        </div>
                                                    </div>

                                                </InfoWindow>
                                            }
                                        </>
                                    ))}

                                </Map>
                            </APIProvider>

                        </div>

                    </div>
                </div>
            </div>
        </div >

    );
};




import { APIProvider, InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import moment from 'moment';
import { useLayoutEffect, useMemo, useState } from "react";
import { ApiMapKey } from '../../../helper/BaseUrl';

export const GoogleMapSingleView = (props) => {
    const { cancelbtnphoto, AllGroupTreeMap, heading } = props

    const [Lat, setLat] = useState()
    const [Long, setLong] = useState()

    // const position = { lat: AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.treeLatitude, lng: AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.treeLongitude };

    useLayoutEffect(() => {
        ParseDMSLat(AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.latitude)
        ParseDMSLong(AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.longitude)
    }, [])

    function ParseDMSLat(input) {
        var parts = input.split(/[^\d\w\.]+/);
        ConvertDMSToDDLat(parts[0], parts[1], parts[2], parts[3]);
    }

    function ConvertDMSToDDLat(degrees, minutes, seconds, direction) {
        var dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);
        if (direction == "S" || direction == "W") {
            dd = dd * -1;
        } // Don't do anything for N or E
        setLat(dd)
        return dd;
    }

    function ParseDMSLong(input) {
        var parts = input.split(/[^\d\w\.]+/);
        ConvertDMSToDDLong(parts[0], parts[1], parts[2], parts[3]);
    }


    function ConvertDMSToDDLong(degrees, minutes, seconds, direction) {
        var dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);
        if (direction == "S" || direction == "W") {
            dd = dd * -1;
        } // Don't do anything for N or E
        setLong(dd)
        return dd;
    }

    const position = { lat: Lat, lng: Long };

    const [infoWindowOpen, setInfoWindowOpen] = useState({
        show: false,
        lattitude: '',
        longitude: ''
    });

    const showInfoWindow = (lat, long, data) => {
        console.log(lat, long, data)
        setInfoWindowOpen({
            show: true,
            lattitude: position.lat,
            longitude: position.lng,
            data: data
        });
    };

    const closeInfoWindow = () => {
        setInfoWindowOpen({
            show: false,
            lattitude: '',
            longitude: '',
            data: ''
        });
    };

    const containerStyle = {
        width: '87vw',
        height: '80vh'
    };


    return (
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center',justifyContent:"center", backgroundColor: "#00000090" }}>
            {/* <div className="modal-dialog modal-xl" role="document" style={{ width: '98%', height: '97%' }}> */}
            <div className="modal-fullscreen-xl-down" role="document" >
                <div className="modal-content p-3">
                    <div className="modal-header pt-0">

                        <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle"><span style={{ color: '#DC3545' }}>{AllGroupTreeMap?.data?.treeName}</span> Animal Location Details</h5>
                        <button onClick={() => cancelbtnphoto()} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0">

                        <div className="">

                            {/* <APIProvider apiKey={'AIzaSyCj53sWEgwzj-FtjijWXzH1ngmREzfwmSU'}> */}
                            <APIProvider apiKey={'AIzaSyBReqWPgtFbd8bY69J4UJbuWXTa4E5sDNg'}>
                                <Map defaultCenter={position} defaultZoom={20} style={containerStyle} mapTypeControl={false}>

                                    <Marker
                                        position={{ lat: Lat, lng: Long }}
                                        icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
                                        onClick={() => showInfoWindow(AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.treeLatitude, AllGroupTreeMap && AllGroupTreeMap.data && AllGroupTreeMap.data.treeLongitude, AllGroupTreeMap && AllGroupTreeMap.data)}
                                    />
                                    {
                                        infoWindowOpen.show &&
                                        <InfoWindow
                                            position={{ lat: infoWindowOpen.lattitude, lng: infoWindowOpen.longitude }}
                                            headerContent={<h3 style={{ fontSize: '15px', color: 'red' }}>Animal Details</h3>}
                                            onClose={closeInfoWindow}
                                        >

                                            <div className='marker-popup py-2' style={{width:'400px'}}>
                                                <hr style={{ marginTop: '0px' }} />
                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Animal Name</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.animalName ? infoWindowOpen?.data.animalName : '-'}</div>
                                                </div>

                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>State</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.stateName ? infoWindowOpen?.data.stateName : '-'}</div>
                                                </div>

                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>District</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.districtName ? infoWindowOpen?.data.districtName : '-'}</div>
                                                </div>
                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Block</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.blockName ? infoWindowOpen?.data.blockName: '-'}</div>
                                                </div>
                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Season</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.season ?infoWindowOpen?.data.season : '-'}</div>
                                                </div>

                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Lattitude</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.latitude ? infoWindowOpen?.data.latitude : '-'}</div>
                                                </div>

                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Longitude</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.longitude ? infoWindowOpen?.data.longitude : '-'}</div>
                                                </div>
                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Day/Night</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.dayNight ? infoWindowOpen?.data.dayNight : '-'}</div>
                                                </div>
                                                <div className='row m-1 my-2'>
                                                    <div className='col-5 fw-bold'>Shift</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    <div className='col-6 fw-bold'>{infoWindowOpen?.data.shiftName ? infoWindowOpen?.data.shiftName : '-'}</div>
                                                </div>


                                                <div className='row m-1'>
                                                    <div className='col-5 fw-bold'> Photo</div>
                                                    <div className='col-1 fw-bold'>:</div>
                                                    {
                                                        // infoWindowOpen?.data.animal_Photo == 'Plantation' ?
                                                            <div className='col-6 fw-bold'>
                                                                {
                                                                    infoWindowOpen?.data?.animal_Photo ?
                                                                        <span className='cursor-pointer' onClick={() => window.open(infoWindowOpen?.data?.animal_Photo)}>
                                                                            <i className="fa-solid fa-image fs-5 "></i>
                                                                        </span>
                                                                        :
                                                                        <span>-</span>
                                                                }
                                                            </div>
                                                            // :
                                                            // <div className='col-6 fw-bold'>
                                                            //     {
                                                            //         infoWindowOpen?.data?.afterPhoto ?
                                                            //             <>
                                                            //                 <span className='cursor-pointer' onClick={() => window.open(infoWindowOpen?.data?.afterPhoto)}>
                                                            //                     <i className="fa-solid fa-image fs-5 "></i>
                                                            //                 </span>
                                                            //             </>
                                                            //             :
                                                            //             <span>-</span>
                                                            //     }
                                                            // </div>
                                                    }

                                                </div>
                                            </div>

                                        </InfoWindow>
                                    }

                                </Map>
                            </APIProvider>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};


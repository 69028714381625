
import { Bar, Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Season } from "../../CommonDDL/Season";
import { Animal, AnimalNameMultiCheckBox } from "../../CommonDDL/Animal";
import { StateNameMultiCheckBox } from "../../CommonDDL/State";
import DayNightGridPopUp from "./Modal/DayNightGridPopUp";
import { DashboardStateNameMultiCheckBox } from "../../CommonDDL/DashboardMultiCheckStateNameDDL";
import { DashboardAnimalNameMultiCheckBox } from "../../CommonDDL/DashBoardMultiCheckAnimalNameDDL";
import { DayNightWiseAnimalBar } from "./Modal/Bar/DayNightWiseAnimalBar";
import { useAuthState } from "../../../helper/Context/context";
import { DashboardDistrictNameMultiCheckBox } from "../../CommonDDL/DashboardMultiCheckDistrictNameDDL";

Chart.plugins.register(ChartDataLabels);

export const DayNightWiseAnimalDeath = (props) => {
    const { DataObj } = props
    const {
        Countdata,
        DashStateisCountLoadingIDData,
        YearValue,
        MonthValue
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails


    // ########### Start Multi State ##############

    const [YearSelected, setYearSelected] = useState('0')

    const [MultiStateName, setMultiStateName] = useState([]);
    const [StateSelected, setStateSelected] = useState([]);

    const handleMultiStateData = (e) => {
        if (e && e.length > 0) {
            setStateSelected(e)
            setMultiStateName(e.map(i => i.value))

            setAnimalName([])
            setAnimalSelected([])
        } else {
            setStateSelected([])
            setMultiStateName([])

            setAnimalName([])
            setAnimalSelected([])
        }
    }
    // ########### End Multi State ##############

    // ########### Start Multi Animal  ##############
    const [AnimalName, setAnimalName] = useState([]);
    const [AnimalSelected, setAnimalSelected] = useState([]);

    // useEffect(() => {
    //     setAnimalName([])
    //     setAnimalSelected([])
    // }, [DashRegionalIDData])

    const handleMultiAnimalsData = (e) => {
        if (e && e.length > 0) {
            setAnimalSelected(e)
            setAnimalName(e.map(i => i.value))
        } else {
            setAnimalSelected([])
            setAnimalName([])
        }
    }
    // ########### End Multi Regional OFC ##############

    // ########### Start Multi District ##############

    const [MultiDistrictName, setMultiDistrictName] = useState([]);
    const [DistrictSelected, setDistrictSelected] = useState([]);


    const handleMultiDistrictData = (e) => {
        if (e && e.length > 0) {
            setDistrictSelected(e)
            setMultiDistrictName(e.map(i => i.value))
        } else {
            setDistrictSelected([])
            setMultiDistrictName([])
        }
    }
    // ########### End Multi District ##############

    return (
        <>

            <div className="row mt-3">

                <div className="col-12  bg-white py-1  shadows rounded">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h6 className="dashboard_heading fw-bold pt-3 pb-1" >Day Night Wise Accidental Animal</h6>

                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-4">
                                    <div className="row">
                                        <div className="col-3 pt-3">
                                            <h6 className=" float-end">State</h6>
                                        </div>
                                        <div className="col-9 pt-1">
                                            <DashboardStateNameMultiCheckBox
                                                handleMultiStateData={handleMultiStateData}
                                                MultiStateName={MultiStateName}
                                                setMultiStateName={setMultiStateName}
                                                StateSelected={StateSelected}
                                                setStateSelected={setStateSelected}
                                            // apiFlag='State'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-11 col-lg-4">
                                    <div className="row">
                                        <div className="col-4 col-lg-4 pt-3">
                                            <h6 className="float-end">District</h6>
                                        </div>
                                        <div className="col-8 pt-1">
                                            <DashboardDistrictNameMultiCheckBox
                                                handleMultiDistrictData={handleMultiDistrictData}
                                                MultiDistrictName={MultiDistrictName}
                                                setMultiDistrictName={setMultiDistrictName}
                                                DistrictSelected={DistrictSelected}
                                                setDistrictSelected={setDistrictSelected}
                                                MultiStateName={MultiStateName}
                                            // apiFlag='State'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-4">
                                    <div className="row">
                                        <div className="col-4 pt-3">
                                            <h6 className=" float-end">Animals</h6>
                                        </div>
                                        <div className="col-8 pt-1">
                                            <DashboardAnimalNameMultiCheckBox
                                                handleMultiAnimalsData={handleMultiAnimalsData}
                                                AnimalName={AnimalName}
                                                setAnimalName={setAnimalName}
                                                AnimalSelected={AnimalSelected}
                                                setAnimalSelected={setAnimalSelected}
                                                MultiStateName={MultiStateName}
                                                MultiDistrictName={MultiDistrictName}
                                            // apiFlag='State'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <DayNightWiseAnimalBar
                    DataObj={DataObj}
                    MultiStateName={MultiStateName}
                    MultiDistrictName={MultiDistrictName}
                    AnimalName={AnimalName} />

            </div>
        </>
    )
}
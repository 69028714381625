
import { Bar, Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Select from "react-select";
import SeasonWiseAnimalPopUp from "../Modal/SeasonWiseAnimalPopUp";
import { seasonWiseAnimalBarDataAPI } from "../../../../Redux/Slices/DashboardSlice/SeasonWiseAnimalDeathSlice/SeasonWiseAnimalDeathSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../helper/Context/context";
import { ClipLoaderfn } from "../../../../helper/BeatLoader";

Chart.plugins.register(ChartDataLabels);
Chart.Legend.prototype.afterFit = function () {
    this.height = this.height + 40;
};
export const SeasonWiseBar = (props) => {

    const { DataObj, MultiStateName,MultiDistrictName, AnimalName } = props
    const {
        Countdata,
        DashStateisCountLoadingIDData,
        YearValue,
        MonthValue
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const [BarColor, setBarColor] = useState([])
    const [BarshowPopup, setBarshowPopup] = useState(false)
    const [loading, setloading] = useState(false)
    const [LableName, setLableName] = useState({
        paramNameID: '',
        paramName: '',
        AnimalNameLabel: '',
        StateNameId: '',
    })
    const [SeasonWiseAnimalCountBarData, setSeasonWiseAnimalCountBarData] = useState([])

    const handleAddBarShow = () => {
        setBarshowPopup(!BarshowPopup)
    }


    const stateNames = SeasonWiseAnimalCountBarData.map(state => state.animalname);

    const seasonsNames = [];
    SeasonWiseAnimalCountBarData?.forEach(state => {
        state.seasonDetails?.forEach(Season => {
            if (!seasonsNames.some(a => a.seasionID === Season.seasionID)) {
                seasonsNames.push(Season);
            }
        });
    });

    useEffect(() => {
        for (var i = 0; i < seasonsNames.length; i++) {
            dynamicColors()
        }
        setBarColor(coloR)
    }, [SeasonWiseAnimalCountBarData])

    const colors = ['#a11f1d', '#e17646', '#4169E1',];

    const datasets = seasonsNames.map((Season, index) => ({
        label: Season.seasionName,
        data: SeasonWiseAnimalCountBarData.map(state => {
            const seasonDetail = state.seasonDetails.find(detail => detail.seasionID === Season.seasionID);
            return seasonDetail ? (seasonDetail.totalCount == 0 ? null : seasonDetail.totalCount) : null;
        }),
        backgroundColor: colors[index % colors.length],
        // backgroundColor: "#c95035",
        // borderColor: "#96ad36",
        borderWidth: 1.5,
        maxBarThickness: 23,
        spanGaps: true,

    }));



    useEffect(() => {
        setloading(true)
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: MonthValue ? MonthValue : 0,
            // M_StateID: 0,
            M_StateID: MultiStateName && MultiStateName.length > 0 ? MultiStateName : 0,
            M_DistrictNameID: MultiDistrictName && MultiDistrictName.length > 0 ? MultiDistrictName : 0,
            M_AnimalID: AnimalName && AnimalName.length > 0 ? AnimalName : 0,
            Flag: "SeasonWiseAnimalCount",
            FromTop: 1,
            ToTop: 99999,
            M_UserID: UserID,
            token: token,
            setSeasonWiseAnimalCountBarData: setSeasonWiseAnimalCountBarData,
            setloading: setloading,
            dynamicColors: dynamicColors
        }
        if ((YearValue > 0)) {
            dispatch(seasonWiseAnimalBarDataAPI({ data }))
        }
    }, [YearValue, MonthValue, MultiStateName,MultiDistrictName, AnimalName])

    const { isCountLoading } = useSelector(state => state.seasonWiseAnimalBarData)

    const hadleGetName = (Label, AnimalNameLabel) => {
        if (Label != '') {
            var item = SeasonWiseAnimalCountBarData && SeasonWiseAnimalCountBarData?.find(item => item.animalname == Label);
            var data = seasonsNames && seasonsNames?.find(data => data.seasionName == AnimalNameLabel);

            // console.log("data", data.seasionID)

            setLableName({
                paramName: Label,
                paramNameID: data?.seasionID,
                AnimalNameLabel: AnimalNameLabel,
                StateNameId: item.m_AnimalID
            });
            setBarshowPopup(!BarshowPopup)
        }
    }
    // console.log("LableName", LableName.paramNameID)

    const coloR = []

    var dynamicColors = function () {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        // console.log("klhhh","rgb(" + r + "," + g + "," + b + ")")
        coloR.push("rgb(" + r + "," + g + "," + b + ")")
        return "rgb(" + r + "," + g + "," + b + ")";
    };

    const IntercrpingCompBar = {
        responsive: true,
        maintainAspectRatio: false,
        skipNull: true,
        interaction: {
            mode: 'index'
        },
        layout: {
            padding: {
                top: 15,
                bottom: 0, left: 0
            },
        },
        legend: {
            display: true,

            // position: 'top',
            // align: 'start',
            // fit: {
            //     height: 10
            // }
        },

        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                rotation: -90, // Rotate the labels to be vertical
                color: 'black',
                font: {
                    // weight: 'bold'
                    size: 14
                }
            },
            labels: {
                render: 'value',
                fontColor: 'black',
                display: false

            },
            legend: {
                display: false,
            },
            outlabels: {
                color: "black",
                // text: "%l\n%v (%p)",
                stretch: 15,
                font: {
                    resizable: true,
                    minSize: 12,
                    maxSize: 16,
                }
            },
        },

        onClick: (e, elements) => {
            const chart = elements && elements[0] ? elements[0]._chart : null;
            const element = chart && chart.getElementAtEvent(e)[0];
            const dataset = element && chart.data.datasets[element._datasetIndex];
            const xLabel = chart && chart.data.labels[element._index];
            // const value = dataset.data[element._index];
            // console.log(dataset.label + " at " + xLabel + ": " + value);

            // var LabelName = this.getElementsAtEvent(e).length > 0 ? this.getElementsAtEvent(e)[0]?._model?.label : '';
            // console.log("xLabel", element && chart.data.datasets[element._datasetIndex])
            if (xLabel, dataset?.label) {
                console.log("dataset",dataset)
                hadleGetName(xLabel, dataset?.label);
            }
        },

        scales: {

            xAxes: [{
                // stacked: true,
                gridLines: {
                    drawOnChartArea: false,
                    color: '#1b4b7b',
                    zeroLineColors: '#1b4b7b',
                    // borderDash : [5,5]
                },
                barThickness: 25,
                ticks: {
                    autoSkip: false,
                    // fontStyle: 'bold',
                    fontSize: '13',
                    fontColor: '#1b4b7b',
                    maxRotation: 90,
                    minRotation: 90
                }

            }],
            yAxes: [{
                // stacked: false,
                gridLines: {
                    drawOnChartArea: false,
                    color: '#1b4b7b',
                    zeroLineColors: '#1b4b7b',
                    // borderDash : [5,5]
                },
                ticks: {
                    beginAtZero: true,
                    // fontStyle: 'bold',
                    fontSize: '12',
                    fontColor: '#1b4b7b',
                    stepSize: 10,
                    callback: (value, index, values) => {
                        return ` ${value} `
                    }
                }
            }]
        }
    }

    const FirstCompdata = {
        labels: stateNames,
        datasets: datasets
    };

    // console.log('FirstCompdata', FirstCompdata)

    return (
        <>
           
                {
                    loading ?
                        <div className="row child d-flex justify-content-center align-item-center " style={{ height: '100vh' }}>
                            <ClipLoaderfn customcolor='#EF4069' />
                        </div>
                        :
                        <>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 pt-2 m-0">
                                {
                                    SeasonWiseAnimalCountBarData && SeasonWiseAnimalCountBarData.length > 0 ?
                                        <div className="" style={{ overflow: 'scroll' }}>
                                            <Bar
                                                options={IntercrpingCompBar}
                                                data={FirstCompdata}
                                                // onElementsClick={handleAddBarShow}
                                                height={'400px'}
                                                width="auto"
                                                // width={SeasonWiseAnimalCountBarData && SeasonWiseAnimalCountBarData.length > 4 ? '2500px' : '1150px'}
                                                className={'cursor-pointer'}
                                            />
                                        </div>
                                        :
                                        <div className="row container" style={{ height: '40vh' }}>
                                            <div>
                                            <img src="/Static/Assets/Img/chart.png" style={{ width: '8%', marginTop: '-2%' }} />
                                                <text className="mx-3 orange">Data Not Available ..</text>
                                            </div>
                                        </div>
                                }
                            </div>

                        </>
                }
     

            {
                BarshowPopup &&

                <SeasonWiseAnimalPopUp
                    handleAddBarShow={handleAddBarShow}
                    // Title={`Average Canopy Of ${LableName} (ft)`}
                    LableName={LableName}
                    DataObj={DataObj}
                    MultiStateName={MultiStateName}


                />
            }

        </>
    )
}
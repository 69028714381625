
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../helper/BaseUrl";


// --------------------State Name----------------------

export const StateNameDDLAPI = createAsyncThunk("StateNameDDL", async ({ data }) => {
    const {
        Flag,
        M_MonthID,
        M_FinancialYearID,
        M_UserID,
        Top,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        setStateNameData,
        setDistrictNameData,
        setAnimalData,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_Web_DB_DDL_Select?M_MonthID=${M_MonthID}&M_FinancialYearID=${M_FinancialYearID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                if (Flag == "State") {
                    setStateNameData(result.data.table)
                }
                if (Flag == "Animal") {
                    setAnimalData(result.data.table)
                } if (Flag == "District") {
                    setDistrictNameData(result.data.table)
                }
                return result.data
            } else {
                return result
            }
        })
})

const StateNameDDLSlice = createSlice({
    name: "StateNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(StateNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(StateNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(StateNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const StateNameDDLReducer = StateNameDDLSlice.reducer

// ------------------- Year DDL ---------------------

export const YearDDLAPI = createAsyncThunk("YearDDL", async ({ data }) => {
    const {
        M_UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_FinancialYear_Select?M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const YearDDLSlice = createSlice({
    name: "YearDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(YearDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(YearDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(YearDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const YearDDLReducer = YearDDLSlice.reducer

// -- MultiCheck State Name DDL

export const MultiStateNameDDLAPI = createAsyncThunk("MultiStateNameDDL", async ({ data }) => {
    const {
        Flag,
        M_MonthID,
        M_FinancialYearID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        M_UserID,
        Top,
        handleData,
        // setMultiStateNameData,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_Web_DB_DDL_Select?M_MonthID=${M_MonthID}&M_FinancialYearID=${M_FinancialYearID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleData(result.data.table)
                return result.data
            } else {
                return result
            }
        })
})

const MultiStateNameDDLSlice = createSlice({
    name: "MultiStateNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MultiStateNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MultiStateNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(MultiStateNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const MultiStateNameDDLReducer = MultiStateNameDDLSlice.reducer

// -- MultiCheck State Name DDL

export const MultiDistrictNameDDLAPI = createAsyncThunk("MultiDistrictNameDDL", async ({ data }) => {
    const {
        Flag,
        M_MonthID,
        M_FinancialYearID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        M_UserID,
        Top,
        handleData,
        // setMultiStateNameData,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_Web_DB_DDL_Select?M_MonthID=${M_MonthID}&M_FinancialYearID=${M_FinancialYearID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleData(result.data.table)
                return result.data
            } else {
                return result
            }
        })
})

const MultiDistrictNameDDLSlice = createSlice({
    name: "MultiDistrictNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MultiDistrictNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MultiDistrictNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(MultiDistrictNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const MultiDistrictNameDDLReducer = MultiDistrictNameDDLSlice.reducer

// -- MultiCheck Animal Name DDL

export const MultiAnimalNameDDLAPI = createAsyncThunk("MultiAnimalNameDDL", async ({ data }) => {
    const {
        Flag,
        M_MonthID,
        M_FinancialYearID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        M_UserID,
        Top,
        handleData,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    return fetch(`${BaseUrl}/CommonDLLData/Get_Web_DB_DDL_Select?M_MonthID=${M_MonthID}&M_FinancialYearID=${M_FinancialYearID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleData(result.data.table)
                return result.data
            } else {
                return result
            }
        })
})

const MultiAnimalNameDDLSlice = createSlice({
    name: "MultiAnimalNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MultiAnimalNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MultiAnimalNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(MultiAnimalNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const MultiAnimalNameDDLReducer = MultiAnimalNameDDLSlice.reducer
import { useState } from 'react'
import Select from 'react-select'
import Header from "../Header/Header";
import Siderbar from "../Sidebar/Sidebar";
import { MainCotent } from "../MainCotent/MainCotent";
import { Footer } from '../Footer/Footer';
// import { useAuthState } from "./Helper/Context/context";
// import { useDispatch } from "react-redux";
// import Header from "../Header/Header";

function Namita() {
    const [isopen, setIsOpen] = useState(false);

    // console.log(Data.logoPhoto)

    //   const userDetails = useAuthState();
    //   const { UserID, token, } = userDetails
    //   const dispatch = useDispatch()

    // const currentYear = new Date().getFullYear();

    //   const [YearValue, setYearValue] = useState(null)
    //   const [YearLabel, setYearLabel] = useState(null)
    //   const [MonthValue, setMonthValue] = useState(null)
    //   const [MonthLabel, setMonthLabel] = useState(null)

    const toggle = () => {
        setIsOpen(!isopen);

    };

    const [StateID, setStateID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const options = [
        { id: 1, value: '', label: '1' },
        { id: 2, value: '', label: '2' },



    ]
    return (
        <>
            {/* <div className="wrapper"> */}
            <div className="backrounf bg_color_all" style={{ marginLeft: '5px' }}>
                <div className="container-scroller ">
                    <Header
                        // setYearValue={setYearValue}
                        // setYearLabel={setYearLabel}
                        // setMonthValue={setMonthValue}
                        // setMonthLabel={setMonthLabel}
                        toggle={toggle}
                        isopen={isopen} />
                    <div className="container-fluid page-body-wrapper">
                        <Siderbar isopen={isopen} toggle={toggle} active="Master" isactive="Namita" />
                        <div className="main-panel main_wrapping content_wrapper_planting margin_top_75  bg_red" style={{ width: isopen ? "calc(100%  - 102px )" : "", marginLeft: isopen ? "100px" : "" }}>
                            <div className="content-wrapper px-1 ">

                                <div className="container-fluid px-4 py-3">
                                    <h5 className="text-black pb-1 fw-bold">Master</h5>

                                    <div className="row wrapper_style m-0">

                                        <div className="col-12 col-lg-4">
                                            <label for="name" className="form-label fw-bold">Example textarea</label>
                                            <Select
                                                id='StateID'
                                                isClearable={false}
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={130}
                                                value={{ value: StateID.ID, label: StateID.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setStateID({ ...StateID, ID: e.value, Label: e.label })
                                                        :
                                                        setStateID({ ...StateID, ID: 0, Label: "Select..." })

                                                }}
                                                options={options}

                                            />

                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <label for="name" className="form-label fw-bold">Example textarea</label>
                                            <input type="text" placeholder="" name="name" className="form-control" id="name" required />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <label for="name" className="form-label fw-bold">Example textarea</label>
                                            <input type="text" placeholder="" name="name" className="form-control" id="name" required />
                                        </div>



                                        <div className="col-12 pt-3">

                                            <table class="scrolldown">
                                                <thead>
                                                    <tr className="border_radius">
                                                        <th>Sr. No.</th>
                                                        <th>Contact</th>
                                                        <th>Country</th>
                                                        <th>Country</th>
                                                        <th>Country</th>
                                                        <th>Country</th>
                                                        <th>Country</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>Maria Anders</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                        <td>Germany</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <div className="row pt-3">
                                                <div className="col-12 ">
                                                    <nav aria-label="...">
                                                        <ul class="pagination float-end">
                                                            <li class="page-item disabled">
                                                                <a class="page-link">  <span aria-hidden="true">&laquo;</span> Previous</a>
                                                            </li>
                                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                            <li class="page-item active" aria-current="page">
                                                                <a class="page-link" href="#">2</a>
                                                            </li>
                                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                            <li class="page-item">
                                                                <a class="page-link" href="#">Next <span aria-hidden="true">&raquo;</span></a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* </div> */}
        </>
    );
}

export default Namita;

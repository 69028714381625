import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../helper/Context/context";
import { MultiStateNameDDLAPI, StateNameDDLAPI } from "../../Redux/Slices/DDLSlice/DDLSlice";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

var options = [];

export const DashboardStateNameMultiCheckBox = (props) => {
    const { MultiStateName, setMultiStateName, setStateSelected, StateSelected, IsPost, Flag, apiFlag,handleMultiStateData } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [options, setOptions] = useState([]);

    const handleData = (val) => {
        const StateNameData = val.map(i => ({
            label: i.stateName,
            value: i.m_StateNameID
        }));
        // setMultiStateNameData(handleData)
        setOptions(StateNameData);
    }

    useEffect(() => {
        const data = {
            M_MonthID: 0,
            M_FinancialYearID: 0,
            M_UserID: UserID,
            M_StateID : 0 ,
            M_DistrictNameID :0,
            M_AnimalID :0,
            Flag: 'State',
            Top: "99999",
            token: token,
            handleData: handleData,
        }
        { dispatch(MultiStateNameDDLAPI({ data })) }
    }, [])

    const { DDLData,isLoading } = useSelector(state => state.StateNameDDLData)

    return (
        <div>
            {/* <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Project Name : </label> */}
            <MultiSelect
                options={options}
                value={StateSelected}
                onChange={(e) => handleMultiStateData(e)}
                labelledBy="Select..."
                isLoading={isLoading}
            />
        </div>
    );
};


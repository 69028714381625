import { MultiSelect } from "react-multi-select-component";
import { useAuthState } from "../../helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MultiAnimalNameDDLAPI, MultiStateNameDDLAPI } from "../../Redux/Slices/DDLSlice/DDLSlice";

// var options = [];

export const DashboardAnimalNameMultiCheckBox = (props) => {
    const { AnimalName, setAnimalName, setAnimalSelected, AnimalSelected,MultiDistrictName, ContractorName, Flag, handleMultiAnimalsData, MultiStateName, StateSelected } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [options, setOptions] = useState([]);

    const handleData = (val) => {
        const AnimalNameData = val.map(i => ({
            label: i.animalName,
            value: i.m_AnimalID
        }));
        setOptions(AnimalNameData);
    }

    // useEffect(() => {
    //     // options = []
    //     const data = {
    //         M_MonthID: 0,
    //         M_FinancialYearID: 0,
    //         M_UserID: UserID,
    //         Flag: 'Animal',
    //         M_StateID: MultiStateName != 0 ? MultiStateName : 0,
    //         M_AnimalID: 0,
    //         Top: "99999",
    //         token: token,
    //         handleData: handleData
    //     }
    //     if (MultiStateName != '') {
    //         dispatch(MultiAnimalNameDDLAPI({ data }))
    //     }

    // }, [MultiStateName,UserID, token])
    useEffect(() => {
        if (MultiStateName !== '') {
            const data = {
                M_MonthID: 0,
                M_FinancialYearID: 0,
                M_UserID: UserID,
                Flag: 'Animal',
                M_StateID: MultiStateName && MultiStateName.length > 0 ? MultiStateName : 0,
                M_DistrictNameID: MultiDistrictName && MultiDistrictName.length > 0 ? MultiDistrictName : 0,
                M_AnimalID: 0,
                Top: "99999",
                token: token,
                handleData: handleData
            };
           
            dispatch(MultiAnimalNameDDLAPI({ data }));
        }
    }, [MultiStateName,MultiDistrictName, UserID, token]);


    const { isLoading } = useSelector(state => state.MultiAnimalNameDDLData)


    return (
        <div>
            {/* <pre>{JSON.stringify(AnimalSelected.value)}</pre> */}
            <MultiSelect
                options={options}
                value={AnimalSelected}
                onChange={(e) => handleMultiAnimalsData(e)}
                labelledBy="Select..."
                isLoading={isLoading}
            />
        </div>
    );
};







import React from 'react'
import { BarLoader, BeatLoader, ClipLoader, ScaleLoader } from 'react-spinners'

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const BeatLoaderfn = ({ customcolor }) => {
  return (
    <BeatLoader color={customcolor ? customcolor : '#fff'} css={override} size={12} />
  )
}

export const ClipLoaderfn = ({ customcolor }) => {
  return (
    <div 
      className='d-flex justify-content-center align-items-center' style={{ height: '100vh'}}
    >
      <ClipLoader color={customcolor ? customcolor : '#fff'} css={override} size={40} />
    </div>
  )
}

export const BarLoaderfn = ({ customcolor }) => {
  return (
    <div className='container '>
    <BarLoader color={customcolor ? customcolor : '#fff'} css={override} size={40}  />
    </div>
  )
}

export const ScaleLoaderfn = ({ customcolor }) => {
  return (
    <div className='container '>
    <ScaleLoader color={customcolor ? customcolor : '#fff'} css={override} size={40}  />
    </div>
  )
}


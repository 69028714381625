import { Bar, Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../helper/Context/context";
import { DashboardCountDataAPI } from "../../../../Redux/Slices/DashboardSlice/DashboardCountAPISlice/DashboardCountSlice";
import StateWiseAnimalBarPopUp from "../Modal/StateWiseAnimalBarPopUp";
import { ClipLoaderfn } from "../../../../helper/BeatLoader";


Chart.plugins.register(ChartDataLabels);

export const StateWiseBar = (props) => {

    const { DataObj, MultiStateName,MultiDistrictName, barColors } = props
    const {
        Countdata,
        DashStateisCountLoadingIDData,
        YearValue,
        MonthValue
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const [BarColor, setBarColor] = useState([])
    const [BarshowPopup, setBarshowPopup] = useState(false)
    const [loading, setloading] = useState(false)
    const [LableName, setLableName] = useState({
        paramNameID: '',
        paramName: '',
        AnimalNameLabel: '',
        StateNameId: '',
        m_DistrictNameID:''
    })
    const [StateWiseAnimalCountBarData, setStateWiseAnimalCountBarData] = useState([])

    const handleAddBarShow = () => {
        setBarshowPopup(!BarshowPopup)
    }

    // const stateNames = StateWiseAnimalCountBarData.map(state => state.stateName);
    const stateNames = StateWiseAnimalCountBarData.map(state => state.filterName);


    const animalNames = [];
    StateWiseAnimalCountBarData?.forEach(state => {
        state.animalDetails?.forEach(animal => {
            if (!animalNames.some(a => a.m_AnimalNameID === animal.m_AnimalNameID)) {
                animalNames.push(animal);
            }
        });
    });

    // useEffect(() => {
    //     // console.log("dsg",animalNames && animalNames.length)
    //     for (var i = 0; i < animalNames.length; i++) {
    //         dynamicColors()
    //     }
    //     setBarColor(coloR)
    // }, [StateWiseAnimalCountBarData])

    const datasets = animalNames.map((animal, index) => ({
        label: animal.animalName,
        data: StateWiseAnimalCountBarData.map(state => {
            const animalDetail = state.animalDetails.find(detail => detail.m_AnimalNameID === animal.m_AnimalNameID);
            return animalDetail ? animalDetail.totalCount : null;
        }),
        backgroundColor: barColors[index % barColors.length],
        // backgroundColor: BarColor,
        backgroundColor: barColors[index % barColors.length],
        borderWidth: 1.5,
        maxBarThickness: 23,
        spanGaps: true,
    }));


    useEffect(() => {
        setloading(true)
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: MonthValue ? MonthValue : 0,
            M_StateID: MultiStateName ? MultiStateName : 0,
            M_DistrictNameID: MultiDistrictName ? MultiDistrictName : 0,
            Flag: "StateCount",
            M_UserID: UserID,
            token: token,
            setStateWiseAnimalCountBarData: setStateWiseAnimalCountBarData,
            setloading: setloading,
            // dynamicColors: dynamicColors
        }
        if ((YearValue > 0)) {
            dispatch(DashboardCountDataAPI({ data }))
        }
    }, [YearValue, MonthValue, MultiStateName,MultiDistrictName])

    const { isCountLoading } = useSelector(state => state.DashboardCountData)

    const hadleGetName = (Label, AnimalNameLabel) => {
        if (Label != '') {
            var item = StateWiseAnimalCountBarData && StateWiseAnimalCountBarData?.find(item => item.stateName == Label);
            var data = animalNames && animalNames?.find(data => data.animalName == AnimalNameLabel);
            var distictdata = StateWiseAnimalCountBarData && StateWiseAnimalCountBarData?.find(distictdata => distictdata.districtName == Label);


            // console.log("item", data)

            setLableName({
                paramName: Label,
                paramNameID: data?.m_AnimalNameID,
                AnimalNameLabel: AnimalNameLabel,
                StateNameId: MultiStateName,
                m_DistrictNameID:MultiDistrictName

            });
            setBarshowPopup(!BarshowPopup)
        }
    }
    // console.log("LableName", LableName.paramNameID)

    // const coloR = []

    // var dynamicColors = function () {
    //     var r = Math.floor(Math.random() * 255);
    //     var g = Math.floor(Math.random() * 255);
    //     var b = Math.floor(Math.random() * 255);
    //     // console.log("klhhh","rgb(" + r + "," + g + "," + b + ")")
    //     coloR.push("rgb(" + r + "," + g + "," + b + ")")
    //     return "rgb(" + r + "," + g + "," + b + ")";
    // };

    const IntercrpingCompBar = {
        responsive: true,
        maintainAspectRatio: false,
        skipNull: true,
        interaction: {
            mode: 'index'
        },
        layout: {
            padding: {
                top: 15,
                bottom: 0, left: 0
            },
        },
        legend: {
            display: false,
            // position: 'top',
            // align: 'start',
            // fit: {
            //     height: 10
            // }
        },

        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                rotation: -90, // Rotate the labels to be vertical
                color: 'black',
                font: {
                    // weight: 'bold'
                    size: 14
                }
            },
            labels: {
                render: 'value',
                fontColor: 'black',
                display: false

            },
            legend: {
                display: false,
            },
            outlabels: {
                color: "black",
                // text: "%l\n%v (%p)",
                stretch: 15,
                font: {
                    resizable: true,
                    minSize: 12,
                    maxSize: 16,
                }
            },
        },

        onClick: (e, elements) => {
            const chart = elements && elements[0] ? elements[0]._chart : null;
            const element = chart && chart.getElementAtEvent(e)[0];
            const dataset = element && chart.data.datasets[element._datasetIndex];
            const xLabel = chart && chart.data.labels[element._index];
            // const value = dataset.data[element._index];
            // console.log(dataset.label + " at " + xLabel + ": " + value);

            // var LabelName = this.getElementsAtEvent(e).length > 0 ? this.getElementsAtEvent(e)[0]?._model?.label : '';
            // console.log("xLabel", element && chart.data.datasets[element._datasetIndex])
            if (xLabel, dataset?.label) {
                console.log("fghj",dataset?.label)
                hadleGetName(xLabel, dataset?.label);
            }
        },

        scales: {

            xAxes: [{
                // stacked: true,
                gridLines: {
                    drawOnChartArea: false,
                    color: '#1b4b7b',
                    zeroLineColors: '#1b4b7b',
                    // borderDash : [5,5]
                },
                barThickness: 25,
                ticks: {
                    autoSkip: false,
                    // fontStyle: 'bold',
                    fontSize: '13',
                    fontColor: '#1b4b7b',
                    maxRotation: 90,
                    minRotation: 90
                }

            }],
            yAxes: [{
                // stacked: false,
                gridLines: {
                    drawOnChartArea: false,
                    color: '#1b4b7b',
                    zeroLineColors: '#1b4b7b',
                    // borderDash : [5,5]
                },
                ticks: {
                    beginAtZero: true,
                    // fontStyle: 'bold',
                    fontSize: '12',
                    fontColor: '#1b4b7b',
                    stepSize: 10,
                    callback: (value, index, values) => {
                        return ` ${value} `
                    }
                }
            }]
        }
    }

    const FirstCompdata = {
        labels: stateNames,
        datasets: datasets
    };


    return (
        <>

            {/* <h6 className="dashboard_heading fw-bold py-3 bg-white shadows rounded" >State Wise Accidental Animal</h6> */}
            {
                isCountLoading ?
                    <div className="row child">
                        <ClipLoaderfn customcolor='#EF4069' />
                    </div>
                    :
                    <div className="col-12 col-md-12 col-lg-12 col-xl-8 pt-2 m-0 pb-4">
                        {
                            StateWiseAnimalCountBarData && StateWiseAnimalCountBarData.length > 0 ?
                                <div className="" style={{ overflow: 'scroll' }}>
                                    <Bar
                                        options={IntercrpingCompBar}
                                        data={FirstCompdata}
                                        // onElementsClick={handleAddBarShow}
                                        height={'400px'}
                                        width="auto"
                                        // width={StateWiseAnimalCountBarData && StateWiseAnimalCountBarData.length > 2 ? '1500px' : '1050px'}
                                        className={'cursor-pointer'}
                                    />
                                </div>
                                :
                                <div className="row container" style={{ height: '40vh' }}>
                                    <div>
                                        <img src="/Static/Assets/Img/chart.png" style={{ width: '8%', marginTop: '-2%' }} />
                                        <text className="mx-3 orange">Data Not Available ..</text>
                                    </div>
                                </div>
                        }
                    </div>

            }

            {
                BarshowPopup &&

                <StateWiseAnimalBarPopUp
                    handleAddBarShow={handleAddBarShow}
                    // Title={Average Canopy Of ${LableName} (ft)}
                    LableName={LableName}
                    // Title={LableName.paramName}
                    DataObj={DataObj}


                />
            }
        </>
    )
}

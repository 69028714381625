
// var url = window.location.origin
// export const BaseUrl =
//     url === "http://tms.cdat.work" ? "http://apitms.cdat.work" :
//         url === "https://tms.cdat.work" ? "https://apitms.cdat.work" :
//             url === "https://tmsphilips.in" ? "https://api.tmsphilips.in" :
//                 "https://apitms.cdat.work"
// // "https://api.tmsphilips.in"

var url = window.location.origin
export const BaseUrl =
    url === "https://rk.cdat.work" ? "https://apirk.cdat.work" :
    url === "http://vanpathsurakshak.in" ? "http://api.vanpathsurakshak.in" :
    // url === "https://vanpathsurakshak.in" ? "https://api.vanpathsurakshak.in" :
    url === "https://vanpathsurakshak.in" ? "https://api.vanpathsurakshak.in" :
        "https://apirk.cdat.work" 
        // "https://api.vanpathsurakshak.in"
       
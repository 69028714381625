

import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';


export const Loading = () => {
  return (
    <Loader fullPage loading />
  )
}






import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { StateNameDDLAPI } from '../../Redux/Slices/DDLSlice/DDLSlice'
import { useAuthState } from '../../helper/Context/context'

export const DistrictNameDDLData = (props) => {

    const { DistrictNameID,setDistrictNameID,isdefault ,StateNameID, Flag, data, IsPost, apiFlag, AstricSignFlag, LableChangeFlag } = props
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const d = new Date();
    let month = d.getMonth() + 1;
    // var monthMinusOneName = moment().format('MMMM');
    let year = d.getFullYear();
    // var YearLables = moment().format('YYYY');

    var MonthSession = sessionStorage.getItem("monthId")
    var YearSession = sessionStorage.getItem("yearId")

    const [DistrictNameData,setDistrictNameData] =useState([])

    useEffect(() => {
        const data = {
            Flag: apiFlag,
            M_MonthID : 0,
            M_FinancialYearID : 0,
            M_StateID : StateNameID.ID,
            M_DistrictNameID:0,
            M_AnimalID : 0,
            M_UserID : UserID,
            Top : "99999",
            token: token,  
            setDistrictNameData:setDistrictNameData      
        }
        dispatch(StateNameDDLAPI({ data }))
    }, [IsPost,StateNameID.ID])

    const { DDLData } = useSelector(state => state.StateNameDDLData)

    useEffect(() => {
        handleDDL()
    }, [DistrictNameData])

    // console.log("DistrictNameData",DistrictNameData)

    const handleDDL = () => {
        if (DistrictNameData  && DistrictNameData.length > 0) {
            // console.log("DDLData.table", DistrictNameData)
            let list = DistrictNameData.map((item, index) => ({
                value: item.m_DistrictNameID,
                label: item.districtName,
            }))

            if(isdefault){
                setDistrictNameID({
                    DDL: list,
                    ID: DistrictNameData ? DistrictNameData[0]?.m_DistrictNameID : 0,
                    Label: DistrictNameData ? DistrictNameData[0]?.districtName : "Select...",
                })
            }else{
                setDistrictNameID({
                    DDL: list,
                    ID: Flag == 'Update' ? data?.m_DistrictNameID : 0,
                    Label: Flag == 'Update' ? data?.districtName : "Select...",
                })
            }

           
        }

        else {
            setDistrictNameID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            {!isdefault && <h6 className="d-block">District </h6>}
            <Select
                id='DistrictNameID'
                isClearable={false}
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={130}
                value={{ value: DistrictNameID.ID, label: DistrictNameID.Label }}
                onChange={(e) => {
                    e ?
                        setDistrictNameID({ ...DistrictNameID, ID: e.value, Label: e.label })
                        :
                        setDistrictNameID({ ...DistrictNameID, ID: 0, Label: "Select..." })

                }}
                options={DistrictNameID.DDL}
            />
        </div>
    )
}
import React, { useState } from "react";

import { Link } from "react-router-dom";

// import { useAuthState } from "../Helper/Context/context";

export default function Siderbar(props) {
  const { isopen, toggle, active, isactive, DashboardSelected, setDashboardSelected } = props
  // const [toggleactive, settoggleactive] = useState("dashboard")

  //   const userDetails = useAuthState();
  //   const { RoleID } = userDetails

  // console.log('RoleID', RoleID)
  const [bgColor, setBgColor] = useState('');

  const handleClick = () => {
    setBgColor('#595656');

  };

  return (
    <>
      <div

        className={
          isopen
            ? "left-slidebar dark-shadow sidebar-block"
            : "left-slidebar dark-shadow sidebar-none"
        }
        style={{ width: isopen ? "75px" : "220px" }}
      >
        <div className="navbar-brand-wrapper d-flex align-items-center justify-content-center">
          {
            isopen ?
              <span className="navbar-brand brand-logo ey-small-logo" style={{ background: "white", borderRadius: "10px", padding: "8px" }}>
                <img
                  style={{ width: isopen ? "70px" : "", height: '70px' }}
                  src="static/assets/img/logo-01.png"
                  className="logo "

                  alt="short-logo"
                />

              </span>
              :
              <span className="navbar-brand brand-logo ey-logo" style={{ background: "white", borderRadius: "10px", padding: "8px" }}>
                <img
                  style={{ width: isopen ? "90%" : "" }}
                  src="static/assets/img/logo-01.png"
                  className="logo"
                  alt="logo"
                />
              </span>
          }

        </div>

        <nav
          className="sidebar  sidebar-offcanvas"
          id="sidebar"
          style={{ maxHeight: isopen ? "calc(100vh - 150px)" : " " }}
        >

          <ul className="nav d-block" >
            {/* <li
              className={active === "Dashboard" ? " nav-item active" : "nav-item"}
            >
              <Link to="/MainDashboard" className="nav-link ">
                <i class="fa-solid fa-home menu-icon"></i>
                <span
                  className="menu-title"
                  style={{ display: isopen ? "none" : "" }}
                >
                  Dashboard
                </span>
              </Link>
            </li> */}

            <li className="li_hover">
              <a href="#roadKillerDashboard" data-toggle="collapse" aria-expanded="false"
                className="">
                <div className="iconblock" style={{ display: 'flex', paddingLeft: '10px' }}>
                  <div className="innerblock pe-1">
                    <i class="fa-solid fa-home menu-icon" style={{color:'white'}}></i>
                  </div>
                  <p className="text-white menu-title ps-1" style={{ display: isopen ? "none" : "" }}>
                    Accidental Animal
                  </p>
                  <i class="fa-solid fa-caret-down text-white ps-2 pt-1" style={{ fontSize: '10px', display: isopen ? "none" : "" }}></i>
                </div>
              </a>
             
                <ul className=" mx-auto Dropdown_bg_color collapse" id='roadKillerDashboard' >
                  <li
                    className={active === "Total" ? " nav-item active pt-2" : "nav-item pt-2"}
                  >
                    <Link to="/MainDashboard?Type=Total" className="nav-link " onClick={() => setDashboardSelected('1')}>
                      <i className={active === "Total" ? "menu-icon px-1 active1 " : "menu-icon"}>

                        <i class="fa-solid fa-circle fa-2xs bullet-style"></i>
                      </i>
                      <span
                        className="menu-title"
                        style={{ display: isopen ? "none" : "" }}
                      >
                      No. Animals Accident on Road
                      </span>
                    </Link>
                  </li>

                  <li
                    className={active === "StateWiseAccidentalAnimal" ? " nav-item active" : "nav-item"}
                  >
                    <Link to="/MainDashboard?Type=StateWiseAccidentalAnimal" className="nav-link sidebar-dark-text" onClick={() => setDashboardSelected('1')}>
                      <i className={active === "StateWiseAccidentalAnimal" ? "menu-icon active1 " : "menu-icon"}>

                        <i class="fa-solid fa-circle fa-2xs bullet-style"></i>
                      </i>
                      <span
                        className="menu-title"
                        style={{ display: isopen ? "none" : "" }}
                      >
                       State Wise Accidental Animal
                      </span>
                    </Link>
                  </li>

                  <li
                    className={active === "SeasonWiseAccidentalAnimal" ? " nav-item active" : "nav-item"}
                  >
                    <Link to="/MainDashboard?Type=SeasonWiseAccidentalAnimal" className="nav-link sidebar-dark-text " onClick={() => setDashboardSelected('1')}>
                      <i className={active === "SeasonWiseAccidentalAnimal" ? "menu-icon active1 " : "menu-icon"}>

                        <i class="fa-solid fa-circle fa-2xs bullet-style"></i>
                      </i>
                      <span
                        className="menu-title"
                        style={{ display: isopen ? "none" : "" }}
                      >
                        Season Wise Accidental Animal
                      </span>
                    </Link>
                  </li>

                  <li
                    className={active === "DayNightWiseAccidentalAnimal" ? " nav-item active" : "nav-item"}
                  >
                    <Link to="/MainDashboard?Type=DayNightWiseAccidentalAnimal" className="nav-link sidebar-dark-text " onClick={() => setDashboardSelected('1')}>
                      <i className={active === "DayNightWiseAccidentalAnimal" ? "menu-icon active1 " : "menu-icon"}>

                        <i class="fa-solid fa-circle fa-2xs bullet-style"></i>
                      </i>
                      <span
                        className="menu-title"
                        style={{ display: isopen ? "none" : "" }}
                      >
                       Day Night Wise Accidental Animal
                      </span>
                    </Link>
                  </li>

                  <li
                    className={active === "HoursWiseAccidentalAnimal" ? " nav-item active" : "nav-item"}
                  >
                    <Link to="/MainDashboard?Type=HoursWiseAccidentalAnimal" className="nav-link sidebar-dark-text " onClick={() => setDashboardSelected('1')}>
                      <i className={active === "HoursWiseAccidentalAnimal" ? "menu-icon active1 " : "menu-icon"}>

                        <i class="fa-solid fa-circle fa-2xs bullet-style"></i>
                      </i>
                      <span
                        className="menu-title"
                        style={{ display: isopen ? "none" : "" }}
                      >
                        24 Hours Wise Accidental Animal
                      </span>
                    </Link>
                  </li>              
                </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

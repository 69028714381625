import React, { useReducer } from 'react';
import { initialState, AuthReducer } from './reducer';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
const MonthContext = React.createContext();

export function useAuthState() {
	const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}
	return context;
}

export function useAuthDispatch() {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within a AuthProvider');
	}

	return context;
}

export function useMonth() {
	const context = React.useContext(MonthContext);
	if (context === undefined) {
	  throw new Error('useMonth must be used within a MonthProvider');
	}
	return context;
  }

export const AuthProvider = ({ children }) => {
	const [user, dispatch] = useReducer(AuthReducer, initialState);

	return (
		<AuthStateContext.Provider value={user}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthStateContext.Provider>
	);
};


export const MonthProvider = ({ children }) => {
	const [selectedMonth, setSelectedMonth] = React.useState('January'); 
  
	const setMonth = (month) => {
	  setSelectedMonth(month);
	};
  
	return (
	  <MonthContext.Provider value={{ selectedMonth, setMonth }}>
		{children}
	  </MonthContext.Provider>
	);
  };

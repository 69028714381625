import React, { useEffect, useState } from "react";
import { StateNameMultiCheckBox } from "../../../CommonDDL/State";
import { AnimalNameMultiCheckBox } from "../../../CommonDDL/Animal";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../helper/Context/context";
import { seasonWiseAnimalDataAPI, seasonWiseAnimalExportDataAPI } from "../../../../Redux/Slices/DashboardSlice/SeasonWiseAnimalDeathSlice/SeasonWiseAnimalDeathSlice";
import moment from 'moment'
import { Pegination } from "../../../Pegination/Pegination";
import { Loading } from "../../../../helper/Loader/Loading";
import PhotoPopUp from "../../NoOfAnimalAccident/Modal/PhotoPopUp";
import { GoogleMapSingleView } from "../../MapView/GoogleMapSingleView";
import { toastError } from "../../../../helper/AlertMessage/AlertMessage";
import { SeasonWiseAnimalExportData } from "../Export/SeasonWiseAnimalExportData";

export default function SeasonWiseAnimalPopUp(props) {

    const { handleAddBarShow, Title, LableName, handleIsPost, ShowAddModal, DataObj, MultiStateName } = props

    const { paramNameID, paramName, AnimalNameLabel, StateNameId } = LableName

    const {
        YearValue,
        MonthValue
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const ShowBy = sessionStorage.getItem('showBy')

    const [IsPost, setIsPost] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [GridData, setGridData] = useState('')
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [ImageHeading, setImageHeading] = useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [loading, setloading] = useState(false)
    const [SeasonWiseAnimalDeath, setSeasonWiseAnimalDeath] = useState([])
    const [SeasonWiseAnimalDeathExportData, setSeasonWiseAnimalDeathExportData] = useState([])


    const [addPopUpShow, setaddPopUpShow] = useState({
        data: '',
        show: false,
        flag: '',

    })

    const handeladdOPenPopUpClick = (value, data) => {
        setaddPopUpShow({
            data: data ? data : '',
            show: true,
            flag: value,
        })
    }

    const handeladdClosePopUpClick = () => {
        setaddPopUpShow({
            data: '',
            show: false,
            flag: "",
        })
    }

    const [StateNameID, setStateNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [AnimalNameID, setAnimalNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleClear = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsClear(!IsClear)
        setStateNameID({
            ...StateNameID,
            ID: 0,
            Label: "Select...",
        })
        setAnimalNameID({
            ...AnimalNameID,
            ID: 0,
            Label: "Select...",
        })
    }

    const handleShowImage = (ImageUrl) => {
        setshowImagePopup(true)
        setImagePopUpData(ImageUrl)
    }

    useEffect(() => {
        setloading(true)
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthsID: 0,
            M_StateID: MultiStateName && MultiStateName.length > 0 ? MultiStateName : 0,
            M_DistrictNameID:0,
            M_AnimalID: LableName.StateNameId,
            M_IndicatorID: LableName.paramNameID,
            PhotoPath: '',
            M_UserID: UserID,
            Flag: "SeasonWiseAnimalDeath",
            FromTop: From,
            ToTop: To,
            token: token,
            setloading: setloading,
            setSeasonWiseAnimalDeath: setSeasonWiseAnimalDeath,
            handleGetExportData: handleGetExportData
        }

        if (YearValue != '') {
            dispatch(seasonWiseAnimalDataAPI({ data }))
        }
    }, [From, IsClear, YearValue, MonthValue, StateNameID.ID, AnimalNameID.ID, MultiStateName])

    const { isLoading, tableData } = useSelector(state => state.seasonWiseAnimalData)

    const handleGetExportData = () => {
        handleExportData()
    }
    const handleExportData = () => {
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthsID: 0,
            M_StateID: MultiStateName && MultiStateName.length > 0 ? MultiStateName : 0,
            M_DistrictNameID:0,
            M_AnimalID: LableName.StateNameId,
            M_IndicatorID: LableName.paramNameID,
            PhotoPath: '',
            M_UserID: UserID,
            Flag: "SeasonWiseAnimalDeath",
            FromTop: From,
            ToTop: '99999',
            token: token,
            setSeasonWiseAnimalDeath: setSeasonWiseAnimalDeathExportData
        }

        dispatch(seasonWiseAnimalExportDataAPI({ data }))

    }
    const { isExportLoading, ExportTableData } = useSelector(state => state.seasonWiseAnimalExportData)

    const [SingleTreeViewPopup, setSingleTreeViewPopup] = useState({
        show: false,
        data: ''
    })

    const handleSingleTreeViewMapClose = () => {
        setSingleTreeViewPopup({
            show: false,
            data: ''
        })
    }


    let showError = (item => item.assetLatitude === null && item.assetLongitude === null) ? true : false;
    let showMessage = false

    const handleOpenMap = (data) => {
        setloading(true);
        if (data) {
            if (data.latitude !== null && data.longitude !== null) {
                setTimeout(() => {
                    setSingleTreeViewPopup({
                        show: true,
                        data: data
                    });
                    if (!showMessage) {
                        showMessage = true
                    }
                }, 1000);
            } else {
                if (!showError) {
                    toastError('Location Data not available')
                    showError = true;
                }
            }
        }
        setTimeout(() => {
            setloading(false);
        }, 1000);
    }


    return (
        <>
            <div
                className="modal fade show"
                style={{ display: "block", backgroundColor: "#00000055" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header pb-1 pt-2">
                            <h5 className="modal-title ms-4 fw-bold" id="exampleModalLongTitle">
                                {/* {Title} */}{LableName.paramName}
                                <span className="text-danger"> - {LableName.AnimalNameLabel}</span>
                            </h5>
                            <div>
                                <span className="">
                                    {
                                        isExportLoading ?
                                            <button type="button"
                                                className=" export_btn me-5 download-table-xls-button me-1 btn btn-export btn-md mr-1 ml-1" disabled>
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ width: '25px' }}
                                                />Loading...</button>
                                            :
                                            SeasonWiseAnimalDeathExportData && SeasonWiseAnimalDeathExportData?.length > 0 ?
                                                <SeasonWiseAnimalExportData
                                                    ExcelData={SeasonWiseAnimalDeathExportData}
                                                    name={`${LableName.paramName}-${LableName.AnimalNameLabel}`}
                                                />
                                                :

                                                <button disabled type="button" className="btn export_btn me-5 download-table-xls-button me-1 btn btn-export btn-md mr-1 ml-1 rounded">Export</button>
                                    }
                                </span>
                                <button
                                    onClick={() => handleAddBarShow(false)}
                                    type="button"
                                    className="close"
                                    style={{ marginLeft: '1em' }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>

                        {loading && <Loading />}
                        <div className="modal-body mx-4 mt-1" >

                            {/* <div className="row pt-5">
                                <div className="col-3">
                                    <h6>State:</h6>
                                    <StateNameMultiCheckBox

                                        WardIDData={WardIDData}
                                        setWardIDData={setWardIDData}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </div>
                                <div className="col-3">
                                    <h6>Animals:</h6>
                                    <AnimalNameMultiCheckBox

                                        WardIDData={WardIDData}
                                        setWardIDData={setWardIDData}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="table-responsive table-responsive-fix ">
                                    <table className="table table-striped">
                                        <thead className="header_position">
                                            <tr className="">
                                                <th className="w-6 px-3" style={{ whiteSpace: 'nowrap' }}>Sr. No.</th>
                                                <th>Animal Name</th>
                                                <th>State</th>
                                                <th>District</th>
                                                <th>Block</th>
                                                <th>Animal Photo</th>
                                                <th>Latitude/Longitude</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Day/ Night</th>
                                                <th>Shift</th>
                                                <th>Track</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {
                                                SeasonWiseAnimalDeath && SeasonWiseAnimalDeath.length > 0 ? SeasonWiseAnimalDeath.map((i, j) => {
                                                    return (
                                                        <tr className="">
                                                            <td className="py-1 text-center">{i.rowNum}</td>
                                                            <td>{i.animalName ? i.animalName : '-'}</td>
                                                            <td>{i.stateName ? i.stateName : '-'}</td>
                                                            <td>{i.districtName ? i.districtName : '-'}</td>
                                                            <td>{i.blockName ? i.blockName : '-'}</td>


                                                            {
                                                                i?.animal_Photo != null ?
                                                                    <td className="text-center cursor-pointer"
                                                                    >
                                                                        <i class="fa-solid fa-eye text-danger"
                                                                            onClick={() => {
                                                                                handleShowImage(i?.animal_Photo, setImageHeading("Before Photo"))
                                                                            }}
                                                                        ></i>
                                                                    </td>
                                                                    :
                                                                    <td className="text-center">-</td>
                                                            }
                                                            <td>{i.latitude ? i.latitude : '-'}
                                                                <hr className="m-0" />
                                                                {i.longitude ? i.longitude : '-'}</td>

                                                            <td style={{ whiteSpace: 'nowrap' }}>{i.uploadDate ? moment(i.uploadDate).format('DD-MM-YYYY') : '-'}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{i.uploadTime ? i.uploadTime : '-'}</td>
                                                            <td>{i.dayNight ? i.dayNight : '-'}</td>
                                                            <td>{i.shiftName ? i.shiftName : '-'}</td>
                                                            {
                                                                i.latitude && i.longitude != null ?
                                                                    <td className="text-center cursor-pointer">
                                                                        <i class="fa-solid fa-location-dot text-danger"
                                                                            onClick={() => {
                                                                                handleOpenMap(i)
                                                                            }}
                                                                        ></i>
                                                                    </td>
                                                                    :
                                                                    <td className="text-center">-</td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={12}>No data found ...</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {SeasonWiseAnimalDeath && SeasonWiseAnimalDeath.length > 0 &&
                                    <Pegination
                                        PerPageCount={PerPageCount}
                                        TotalCount={SeasonWiseAnimalDeath[0].totalcount}
                                        setFrom={setFrom}
                                        setTo={setTo}
                                        CurrentPage={CurrentPage}
                                        setCurrentPage={setCurrentPage}
                                        IsClear={IsClear}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {SingleTreeViewPopup.show ?
                    <GoogleMapSingleView
                        cancelbtnphoto={handleSingleTreeViewMapClose}
                        heading=""
                        AllGroupTreeMap={SingleTreeViewPopup}
                    />
                    : <></>
                }

                {showImagePopup ?
                    <PhotoPopUp
                        Heading={ImageHeading}
                        setshowImagePopup={setshowImagePopup}
                        ImagePopUpData={ImagePopUpData}
                        loading={loading} />
                    : <></>
                }
            </div>

        </>
    );
}

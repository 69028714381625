import React, { useState } from "react";
import { Loading } from "../../../../helper/Loader/Loading";
const Image_1 = "../assets/img/Loading.png";
const BrokenImage = '../assets/img/not_found.png'

export default function PhotoPopUp({ setshowImagePopup, ImagePopUpData, setipost, ispost, Heading }) {

const [loading,setloading] = useState(true)

    const imageOnLoad = (event) => {
        console.log(event)
       
        console.log(`Picture successfully ${event.currentTarget.src} loaded.`);
        if (event.currentTarget.className !== "error") {
            event.currentTarget.className = "success";
            setloading(false)
        }
    };
    const imageOnError = (event) => {
        event.currentTarget.src = BrokenImage;
        event.currentTarget.className = "error";
        setloading(false)
    };

    console.log("loading",loading)

    return (
        <>
       
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className=" ml-4">
                                <span style={{ fontSize: '19px', fontWeight: 'bold' }}>{Heading ? Heading : 'Tree Photo'}</span>
                            </div>

                            <button type="button" className="btn-close mr-3 mt-1" data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    setshowImagePopup(false)
                                }}>
                                {/* <span className="x" aria-hidden="true">&times;</span> */}
                            </button>
                        </div>
                        {loading && <Loading />}
                        <div className="col-12 modal-body" >
                            <div className="col-12 modal-header justify-content-center" >
                                {/* <img src={ImageNotFound} /> */}
                                {/* {
                                    ImagePopUpData.image === null ?
                                    // <img src={ImageNotFound} />
                                    <h1>hello</h1>
                                    :
                                    <h1>fj</h1>
                                } */}
                                {/* {
                                    ImagePopUpData ?
                                        <img className="col-12" src={ImagePopUpData == null ? "ImageNotFound" : ImagePopUpData} style={{ width: "400px", height: "400px" }} />
                                        : <h4>No Image found</h4>
                                } */}
                                {
                                    (ImagePopUpData || loading) ?
                                        <img className="col-12" style={{ width: "400px", height: "400px" }} src={ImagePopUpData == null ? "ImageNotFound" : ImagePopUpData}
                                            onLoad={imageOnLoad} onError={imageOnError} />
                                        : <img src="static/assets/Img/not_found.png" className="col-12" style={{ width: "400px", height: "400px" }}/>
                                }
                                {/* {

                                        ImagePopUpData ?
                                            <img className="col-12"
                                                src={`data:image/png;base64,${ImagePopUpData}`}
                                                style={{ width: "400px", height: "400px" }}
                                            />

                                            : 'No Image found'
                                } */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




import React, { useState, useReducer } from 'react';
import CryptoJS from "crypto-js";

if (sessionStorage.getItem("roadkiller")) {
	const storedData = sessionStorage.getItem("roadkiller");
	var bytes = CryptoJS.AES.decrypt(storedData, 'RangaFulan09');
	var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	// console.log("decryptedData",decryptedData)

}

let token = decryptedData ? decryptedData.token_type : '';
let UserID = decryptedData ? decryptedData.M_UserID : '';
let RoleID = decryptedData ? decryptedData.RoleID : '';
let M_OfficeMasterID = decryptedData ? decryptedData.M_OfficeMasterID : "";
let UserFullName = decryptedData ? decryptedData.UserFullName : '';
let DesignationID = decryptedData ? decryptedData.DesignationID : "";
let EmployeeID = decryptedData ? decryptedData.EmployeeID : '';
let IsPortalAdmin = decryptedData ? decryptedData.IsPortalAdmin : '';
let DepartmentID = decryptedData ? decryptedData.DepartmentID : '';

export const initialState = {
	token: '' || token,
	UserID: '' || UserID,
	RoleID: '' || RoleID,
	M_OfficeMasterID: '' || M_OfficeMasterID,
	UserFullName: '' || UserFullName,
	DesignationID: '' || DesignationID,
	EmployeeID: '' || EmployeeID,
	IsPortalAdmin: '' || IsPortalAdmin,
	DepartmentID: '' || DepartmentID,
	loading: false,
	errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
	// console.log(action.payload)

	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				token: action.payload.access_token,
				UserID: action.payload.M_UserID,
				RoleID: action.payload.RoleID,
				M_OfficeMasterID: action.payload.M_OfficeMasterID,
				UserFullName: action.payload.UserFullName,
				DesignationID: action.payload.DesignationID,
				EmployeeID: action.payload.EmployeeID,
				IsPortalAdmin: action.payload.IsPortalAdmin,			
				DepartmentID: action.payload.DepartmentID,			
				loading: false,
			};
		case 'LOGOUT':
			return {
				...initialState,
				token: '',
				UserID: '',
				RoleID: '',
				M_OfficeMasterID:'',
				UserFullName: '',				
				DesignationID: '',
				EmployeeID:'',
				IsPortalAdmin: '',
				DepartmentID:''
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../helper/Context/context";
import { MultiDistrictNameDDLAPI, MultiStateNameDDLAPI, StateNameDDLAPI } from "../../Redux/Slices/DDLSlice/DDLSlice";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

var options = [];

export const DashboardDistrictNameMultiCheckBox = (props) => {
    const { MultiDistrictName, seMultiDistrictName, setDistrictSelected, DistrictSelected, MultiStateName, handleMultiDistrictData } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [options, setOptions] = useState([]);

    const handleData = (val) => {
        const DistrictNameData = val.map(i => ({
            label: i.districtName,
            value: i.m_DistrictNameID
        }));
        // setMultiStateNameData(handleData)
        setOptions(DistrictNameData);
    }

// console.log("asd",typeof(MultiStateName && MultiStateName.length > 0))

    useEffect(() => {
        console.log('MultiStateName', MultiStateName && MultiStateName.length)
        const data = {
            M_MonthID: 0,
            M_FinancialYearID: 0,
            M_UserID: UserID,
            M_StateID: MultiStateName && MultiStateName.length > 0 ? MultiStateName : 0,
            M_DistrictNameID: 0,
            M_AnimalID: 0,
            Flag: 'District',
            Top: "99999",
            token: token,
            handleData: handleData,
        }
        if (MultiStateName && MultiStateName.length > 0) {
        dispatch(MultiDistrictNameDDLAPI({ data }))
        } else {
            setOptions([])
        }
    }, [MultiStateName, UserID, token])

    const { DDLData, isLoading } = useSelector(state => state.MultiDistrictNameDDLData)

    return (
        <div>
            {/* <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Project Name : </label> */}
            <MultiSelect
                // disabled={MultiStateName && MultiStateName.length == 0}
                options={options}
                value={DistrictSelected}
                onChange={(e) => handleMultiDistrictData(e)}
                labelledBy="Select..."
                isLoading={isLoading}
            />
        </div>
    );
};



import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { StateNameDDLAPI } from '../../Redux/Slices/DDLSlice/DDLSlice'
import { useAuthState } from '../../helper/Context/context'

export const StateNameDDLData = (props) => {

    const { StateNameID, setStateNameID, Flag, isdefault,data, IsPost, apiFlag, AstricSignFlag, LableChangeFlag } = props
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const d = new Date();
    let month = d.getMonth() + 1;
    // var monthMinusOneName = moment().format('MMMM');
    let year = d.getFullYear();
    // var YearLables = moment().format('YYYY');

    var MonthSession = sessionStorage.getItem("monthId")
    var YearSession = sessionStorage.getItem("yearId")

    const [StateNameData,setStateNameData] =useState([])

    useEffect(() => {
        const data = {
            Flag: apiFlag,
            M_MonthID : 0,
            M_FinancialYearID : 0,
            M_StateID : 0,
            M_DistrictNameID:0,
            M_AnimalID : 0,
            M_UserID : UserID,
            Top : "99999",
            token: token,  
            setStateNameData:setStateNameData      
        }
        dispatch(StateNameDDLAPI({ data }))
    }, [IsPost])

    const { DDLData } = useSelector(state => state.StateNameDDLData)

    useEffect(() => {
        handleDDL()
    }, [StateNameData])

    const handleDDL = () => {
        if (StateNameData  && StateNameData.length > 0) {
            // console.log("DDLData.table", StateNameData)
            let list = StateNameData.map((item, index) => ({
                value: item.m_StateNameID,
                label: item.stateName,
            }))

            if(isdefault){
                setStateNameID({
                    DDL: list,
                    ID: StateNameData ? StateNameData[0]?.m_StateNameID : 0,
                    Label: StateNameData ? StateNameData[0]?.stateName : "Select...",
                })
            }else{
                setStateNameID({
                    DDL: list,
                    ID: Flag == 'Update' ? data?.m_StateNameID : 0,
                    Label: Flag == 'Update' ? data?.stateName : "Select...",
                })
            }
            
        }

        else {
            setStateNameID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            {!isdefault && <h6 className="d-block">State </h6>}
            <Select
                id='StateNameID'
                isClearable={false}
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={130}
                value={{ value: StateNameID.ID, label: StateNameID.Label }}
                onChange={(e) => {
                    e ?
                        setStateNameID({ ...StateNameID, ID: e.value, Label: e.label })
                        :
                        setStateNameID({ ...StateNameID, ID: 0, Label: "Select..." })

                }}
                options={StateNameID.DDL}
            />
        </div>
    )
}
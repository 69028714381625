export const Footer=()=>{
    return(
        <>
            <div className="row px-2 pt-3 pb-0 mb-5 shadow border rounded mt-2">
                <div className="col-12 pt-2 ">
                    <p>© 2024 Copyright. All Rights Reserved.</p>
                </div>
            </div>
        </>
    )
}
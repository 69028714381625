import CryptoJS from "crypto-js";
import { BaseUrl } from "../BaseUrl";

export async function loginUser(dispatch, loginPayload) {

    const { userName, password, setloading, handleRollIdWiseDashboard, handleErrorIdWiseDashboard, setLoginmessage } = loginPayload

    let myHeaders = new Headers();
    // myHeaders.append("accept", "/");

    myHeaders.append("Content-Type", "text/plain");

    // var raw = JSON.stringify({
    //     "username": userName,
    //     "password": password,
    //     "applicationType": "WEB"
    //   });

    
    var raw = `grant_type=password&Username=${userName}&Password=${password}&ApplicationType=WEB`;


    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
    };

    try {
        setloading(true)
        dispatch({ type: 'REQUEST_LOGIN' });
        // let response = await fetch(`${BaseUrl}/token`, requestOptions)
        let response = await fetch(`${BaseUrl}/token`, requestOptions)

        let data = await response.json();

        if (data && data.error == "Invalid Username or Password..") {
            setLoginmessage(data && data.error)
        }

        if (data && data.access_token != "") {
            // if (data && data.Loginmessage != "Invalid Username or Password..") {
            // 
            dispatch({ type: 'LOGIN_SUCCESS', payload: data });
            setloading(false)
            handleRollIdWiseDashboard(data && data)

            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'RangaFulan09').toString();
            try {
                // console.log('try',data)
                sessionStorage.setItem("roadkiller", ciphertext);
                sessionStorage.setItem("A454A", data.RoleID);
                setloading(false)
                sessionStorage.setItem("monthId", '0')
                sessionStorage.setItem("monthLable", 'Select Month')
                // toastSuccesss()
            } catch (error) {

                setloading(false)
                // console.log('catch',error)
                console.error("Error storing data in sessionStorage", error);
            }

            return data && data[0];
        } else {
            setloading(false)
            setLoginmessage(data && data.Loginmessage)
            handleErrorIdWiseDashboard(data && data.Loginmessage)
            dispatch({ type: 'LOGIN_ERROR', error: data.Loginmessage });
            return;
        }
    }
    catch (error) {

        setloading(false)
        handleErrorIdWiseDashboard('Internal Server Error')
        dispatch({ type: 'LOGIN_ERROR', error: error });
        console.log('error');
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    sessionStorage.removeItem('roadkiller');
    sessionStorage.clear()
}


import { Layout } from "react-feather";
import { StateNameMultiCheckBox } from "../../../CommonDDL/State";
import { AnimalNameMultiCheckBox } from "../../../CommonDDL/Animal";
import React, { useEffect, useState } from "react";
import { StateNameDDLData } from "../../../CommonDDL/StateNameDDLData";
import { AnimalNameDDLData } from "../../../CommonDDL/AnimalNameDDLData";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../helper/Context/context";
import { NoOfAnimalsacccideantalExportDataAPI, NoOfAnimalsAccidentalExportDataAPI, NoOfAnimalsardDataAPI } from "../../../../Redux/Slices/DashboardSlice/DashboardCountAPISlice/DashboardCountSlice";
import PhotoPopUp from "./PhotoPopUp";
import moment from 'moment'
import { Pegination } from "../../../Pegination/Pegination";
import { Loading } from "../../../../helper/Loader/Loading";
import { toastError } from "../../../../helper/AlertMessage/AlertMessage";
import { GoogleMapSingleView } from "../../MapView/GoogleMapSingleView";
import { NoOfAccidentalAnimalExportData } from "../Export/NoOfAccidentalAnimalExportData";
import { DistrictNameDDLData } from "../../../CommonDDL/DistrictNameDDLData";

const AddPopupNoOfAnimals = (props) => {

    const { cancelbtnphoto, confirmBtnCallBack, deleteRecord, message, heading, DataObj } = props
    const {
        Countdata,
        isCountLoading,
        YearValue,
        MonthValue,
    } = DataObj

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [IsPost, setIsPost] = useState(false)

    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [GridData, setGridData] = useState('')
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [ImageHeading, setImageHeading] = useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [loading, setloading] = useState(false)
    const [NoOfAnimalData, setNoOfAnimal] = useState([])
    const [NoOfAnimalExortData, setNoOfAnimalExortData] = useState([])

    const [addPopUpShow, setaddPopUpShow] = useState({
        data: '',
        show: false,
        flag: '',

    })

    const handeladdOPenPopUpClick = (value, data) => {
        setaddPopUpShow({
            data: data ? data : '',
            show: true,
            flag: value,
        })
    }

    const handeladdClosePopUpClick = () => {
        setaddPopUpShow({
            data: '',
            show: false,
            flag: "",
        })
    }

    const [StateNameID, setStateNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [DistrictNameID, setDistrictNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [AnimalNameID, setAnimalNameID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleClear = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsClear(!IsClear)
        setStateNameID({
            ...StateNameID,
            ID: 0,
            Label: "Select...",
        })
        setAnimalNameID({
            ...AnimalNameID,
            ID: 0,
            Label: "Select...",
        })
        setDistrictNameID({
            ...DistrictNameID,
            ID: 0,
            Label: "Select...",
        })
    }

    const handleShowImage = (ImageUrl) => {
        setshowImagePopup(true)
        setImagePopUpData(ImageUrl)
    }

    useEffect(() => {
        setloading(true)
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: 0,
            M_StateID: StateNameID.ID,
            M_DistrictNameID: DistrictNameID.ID,
            M_AnimalID: AnimalNameID.ID,
            PhotoPath: '',
            M_UserID: UserID,
            Flag: "NoOfAnimal",
            FromTop: From,
            ToTop: To,
            token: token,
            setloading: setloading,
            setNoOfAnimal: setNoOfAnimal,
            handleGetExportData: handleGetExportData
        }

        if (YearValue != '') {
            dispatch(NoOfAnimalsardDataAPI({ data }))
        }
    }, [From, IsClear, YearValue, MonthValue, StateNameID.ID, AnimalNameID.ID,DistrictNameID.ID])

    const { isLoading, tableData } = useSelector(state => state.NoOfAnimalsardData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [YearValue, MonthValue, StateNameID.ID, AnimalNameID.ID])

    const handleGetExportData = () => {
        handleExportData()
    }
    const handleExportData = () => {
        const data = {
            M_FinancialYearID: YearValue ? YearValue : 0,
            M_MonthID: 0,
            M_StateID: StateNameID.ID,
            M_DistrictNameID: DistrictNameID.ID,
            M_AnimalID: AnimalNameID.ID,
            PhotoPath: '',
            M_UserID: UserID,
            Flag: "NoOfAnimal",
            FromTop: From,
            ToTop: '99999',
            token: token,
            setNoOfAnimal: setNoOfAnimalExortData,
        }

        dispatch(NoOfAnimalsAccidentalExportDataAPI({ data }))

    }
    const { isExportLoading, ExportTableData } = useSelector(state => state.NoOfAnimalsAccidentalExportData)

    const [SingleTreeViewPopup, setSingleTreeViewPopup] = useState({
        show: false,
        data: ''
    })

    const handleSingleTreeViewMapClose = () => {
        setSingleTreeViewPopup({
            show: false,
            data: ''
        })
    }


    let showError = (item => item.assetLatitude === null && item.assetLongitude === null) ? true : false;
    let showMessage = false

    const handleOpenMap = (data) => {
        setloading(true);
        if (data) {
            if (data.latitude !== null && data.longitude !== null) {
                setTimeout(() => {
                    setSingleTreeViewPopup({
                        show: true,
                        data: data
                    });
                    if (!showMessage) {
                        showMessage = true
                    }
                }, 1000);
            } else {
                if (!showError) {
                    toastError('Location Data not available')
                    showError = true;
                }
            }
        }
        setTimeout(() => {
            setloading(false);
        }, 1000);
    }


    return (<>

        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog modal-lg" role="document" >
                <div className="modal-content p-3">

                    <div className="modal-header pb-1 pt-2">
                        <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{heading}</h5>
                        <div>
                            <span className="">
                                {
                                    isExportLoading ?
                                        <button type="button"
                                            className=" export_btn me-5 download-table-xls-button me-1 btn btn-export btn-md mr-1 ml-1" disabled>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ width: '25px' }}
                                            />Loading...</button>
                                        :
                                        NoOfAnimalExortData && NoOfAnimalExortData?.length > 0 ?
                                            <NoOfAccidentalAnimalExportData
                                                ExcelData={NoOfAnimalExortData}
                                                name='No. Animals Accident on Road'
                                            />
                                            :

                                            <button disabled type="button" className="btn export_btn me-5 download-table-xls-button me-1 btn btn-export btn-md mr-1 ml-1 rounded">Export</button>
                                }
                            </span>


                            <button onClick={() => cancelbtnphoto()} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>

                    {loading && <Loading />}
                    <div className="modal-body mx-0">

                        <div className="row">
                            <div className="col-xl-3 col-lg-3 pt-2 mt-3 mt-lg-0 pt-lg-0">
                                <StateNameDDLData
                                    StateNameID={StateNameID}
                                    setStateNameID={setStateNameID}
                                    apiFlag='State'
                                    Flag='Insert'
                                    data=''
                                    IsPost={IsPost}
                                />

                            </div>
                            <div className="col-xl-3 col-lg-3 pt-2 mt-3 mt-lg-0 pt-lg-0">
                                <DistrictNameDDLData
                                    DistrictNameID={DistrictNameID}
                                    setDistrictNameID={setDistrictNameID}
                                    apiFlag='District'
                                    Flag='Insert'
                                    data=''
                                    IsPost={IsPost}
                                    StateNameID={StateNameID}
                                />

                            </div>
                            <div className="col-xl-3 col-lg-3 pt-2 mt-3 mt-lg-0 pt-lg-0">
                                <AnimalNameDDLData
                                    AnimalNameID={AnimalNameID}
                                    setAnimalNameID={setAnimalNameID}
                                    apiFlag='Animal'
                                    Flag='Insert'
                                    data=''
                                    IsPost={IsPost}
                                    StateNameID={StateNameID}
                                    DistrictNameID={DistrictNameID}
                                />

                            </div>
                            <div className="col-xl-3 col-lg-3 pt-2 mt-3 mt-lg-4 pt-lg-1">
                                <button type="button" class="btn btn-danger" onClick={handleClear}>Clear</button>
                            </div>

                        </div>
                        <div className="row pt-3">
                            <div className="table-responsive table-responsive_fix ">
                                <table className="table table-striped ">
                                    <thead className="header_position">
                                        <tr className="">
                                            <th className="w-6 px-3" style={{ whiteSpace: 'nowrap' }}>Sr. No.</th>
                                            <th>Animal Name</th>
                                            <th>State</th>
                                            <th>District</th>
                                            <th>Block</th>
                                            <th>Season </th>
                                            <th>Animal Photo</th>
                                            <th>Latitude/
                                                Longitude</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Day/ Night</th>
                                            <th>Shift</th>
                                            <th>Track</th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        {
                                            NoOfAnimalData && NoOfAnimalData.length > 0 ? NoOfAnimalData.map((i, j) => {
                                                return (
                                                    <tr className="">
                                                        <td className="text-center" >{i.rowNum}</td>
                                                        <td>{i.animalName ? i.animalName : '-'}</td>
                                                        <td>{i.stateName ? i.stateName : '-'}</td>
                                                        <td>{i.districtName ? i.districtName : '-'}</td>
                                                        <td>{i.blockName ? i.blockName : '-'}</td>
                                                        <td>{i.season ? i.season : '-'}</td>
                                                        {
                                                            i.animal_Photo != null ?
                                                                <td className="text-center cursor-pointer"
                                                                >
                                                                    <i class="fa-solid fa-eye text-danger"
                                                                        onClick={() => {
                                                                            handleShowImage(i.animal_Photo, setImageHeading("Before Photo"))
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                                :
                                                                <td className="text-center">-</td>
                                                        }
                                                        <td>{i.latitude ? i.latitude : '-'}
                                                            <hr className="m-0" />
                                                            {i.longitude ? i.longitude : '-'}</td>

                                                        <td style={{ whiteSpace: 'nowrap' }}>{i.uploadDate ? moment(i.uploadDate).format('DD-MM-YYYY') : '-'}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{i.uploadTime ? i.uploadTime : '-'}</td>
                                                        <td>{i.dayNight ? i.dayNight : '-'}</td>
                                                        <td>{i.shiftName ? i.shiftName : '-'}</td>
                                                        {
                                                            i.latitude && i.longitude != null ?
                                                                <td className="text-center cursor-pointer">
                                                                    <i class="fa-solid fa-location-dot text-danger"
                                                                        onClick={() => {
                                                                            handleOpenMap(i)
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                                :
                                                                <td className="text-center">-</td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                                :
                                                <tr>
                                                    <td colSpan={20}>No data found ...</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {NoOfAnimalData && NoOfAnimalData.length > 0 &&
                                <Pegination
                                    PerPageCount={PerPageCount}
                                    TotalCount={NoOfAnimalData[0].totalcount}
                                    setFrom={setFrom}
                                    setTo={setTo}
                                    CurrentPage={CurrentPage}
                                    setCurrentPage={setCurrentPage}
                                    IsClear={IsClear}
                                />
                            }
                        </div>


                    </div>
                </div>
            </div>

            {SingleTreeViewPopup.show ?
                <GoogleMapSingleView
                    cancelbtnphoto={handleSingleTreeViewMapClose}
                    heading=""
                    AllGroupTreeMap={SingleTreeViewPopup}
                />
                : <></>
            }

            {showImagePopup ?
                <PhotoPopUp
                    Heading={ImageHeading}
                    setshowImagePopup={setshowImagePopup}
                    ImagePopUpData={ImagePopUpData} />
                : <></>
            }
        </div>

    </>);
}

export default AddPopupNoOfAnimals;
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { ChevronDown, Power } from "react-feather"
import { Year, YearDDL } from "../CommonDDL/YearDDL";
import { logout } from "../../helper/Context/action";
import { useAuthDispatch, useAuthState } from "../../helper/Context/context";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Header(props) {

  const { setYearValue, setYearLabel, setMonthValue, setMonthLabel, setProjectNameValue, setProjectNameLabel, toggle, isopen } = props

  const navigation = useNavigate()

  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();
  const { user } = userDetails

  const [sticky, setSticky] = useState(false)


  const LogOut = () => {
    logout(dispatch)
    navigation('/')
  }

  const y = new Date();
  let year = y.getFullYear();
  var YearLables = moment().format('YYYY');
  // console.log("year",year,YearLables)

  const currentYear = new Date().getFullYear();
  // console.log("EmployeeName",EmployeeName)

  const [FinancialYear, setFinancialYear] = useState({
    DDL: [],
    ID: sessionStorage.getItem('yearId'),
    Label: sessionStorage.getItem('yearLable'),
  })

  useEffect(() => {
    if (setYearValue !== undefined || sessionStorage.getItem("yearId")) {
      setYearValue(sessionStorage.getItem("yearId") ? sessionStorage.getItem("yearId") : year)
      setFinancialYear({
        ...FinancialYear,
        ID: sessionStorage.getItem("yearId") ? sessionStorage.getItem("yearId") : year,
        Label: sessionStorage.getItem("yearLable") ? sessionStorage.getItem("yearLable") : YearLables,
      })
    }
    if (setYearLabel !== undefined || sessionStorage.getItem("yearLable")) {
      setYearLabel(FinancialYear.Label)
    }
  }, [FinancialYear.ID])

  return (
    <div className="wrapper">
      <div className="row d-flex">
        <nav className="navbar fixed-top d-flex header dark-shadow shadow mt-2 bg_red" style={{ width: isopen ? "calc(100% - 131px)" : "calc(100% - 259px)", marginLeft: isopen ? "117px" : "247px", marginTop: sticky ? "-30px" : "" }} >
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between" >
            <div className="col-4 col-lg-7  d-flex align-items-center">
              <button onClick={toggle}
                className="navbar-toggler navbar-toggler align-self-center"
                type="button"
                data-toggle="minimize"
              >
                <span className="icon-menu"></span>
              </button>
              <ul className="navbar-nav mr-lg-2">
                <li className="nav-item  d-none d-lg-block">
                  <h5 className="ps-3 pt-2 " style={{ fontWeight: '600', color: 'rgb(151 7 7)' }}>Van Path सु-Rakshak</h5>
                </li>
              </ul>
            </div>
            <div className="col-8 col-lg-5">
              <div className="row col-12 d-flex align-items-center justify-content-end">
                <div className="col-8 col-lg-6 p-1">
                  <YearDDL
                    FinancialYear={FinancialYear}
                    setFinancialYear={setFinancialYear}
                    // flag={flag}
                    setYearValue={setYearValue}
                  // Flag={Flag}
                  />
                </div>
                <div className="col-4 p-0">
                  <ul className="navbar-nav navbar-nav-right float-end">
                    <li className="nav-item nav-profile dropdown">
                      <a
                        className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                        <img src="Static/Assets/Img/User.png" alt="profile" />
                        <i className="align-self-center ms-2 text-dark" ><ChevronDown /></i>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right navbar-dropdown text-center" aria-labelledby="profileDropdown" >
                        {/* <i className="text-danger"><Power /></i>
                        <span className="ps-2" onClick={() => LogOut()}> Logout</span> */}
                        <a className="dropdown-item bg-transparant"
                          onClick={() => LogOut()}
                        >
                          <i className="ti-power-off text-primary me-3"><Power size={20} /></i>
                          Logout
                        </a>
                        {/* <Link className="dropdown-item" 
                      // onClick={LogOut}
                      >
                        <i className="text-danger mr-2"><Power /></i>
                        Logout
                      </Link> */}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../../helper/BaseUrl";
import { toastError } from "../../../../helper/AlertMessage/AlertMessage";


// Get Table Data
export const seasonWiseAnimalBarDataAPI = createAsyncThunk("seasonWiseAnimalBarData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        M_UserID,
        FromTop,
        ToTop,
        Flag,
        setloading,
        setSeasonWiseAnimalCountBarData,
        token
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_Web_DB_Animal_Wise_Season_Count_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                setloading && setloading(false)
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data_SeasonWiseAnimal) {
                    setSeasonWiseAnimalCountBarData(result.data_SeasonWiseAnimal)
                    setloading && setloading(false)
                    return result.data
                } else {
                    return result
                }
            } else {
                // toastError(result.message)
                return result
                setloading && setloading(false)
            }
        })
})

const seasonWiseAnimalBarDataSlice = createSlice({
    name: "seasonWiseAnimalBarData",
    initialState: {
        isCountLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(seasonWiseAnimalBarDataAPI.pending, (state, action) => {
            state.isCountLoading = true;
        });
        builder.addCase(seasonWiseAnimalBarDataAPI.fulfilled, (state, action) => {
            state.isCountLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(seasonWiseAnimalBarDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isCountLoading = false;
            state.isError = true;
            state.tableData = null;
            toastError('Something went wrong')
        });
    },
})

export const seasonWiseAnimalBarDataReducer = seasonWiseAnimalBarDataSlice.reducer

// Get Table Data
export const seasonWiseAnimalDataAPI = createAsyncThunk("seasonWiseAnimalData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthsID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        PhotoPath,
        Flag,
        M_UserID,
        FromTop,
        ToTop,
        M_IndicatorID,
        setloading,
        token,
        setSeasonWiseAnimalDeath,
        setDayWiseAnimalDeath,
        setShiftWiseAnimalDeath,
        handleGetExportData
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    setloading && setloading(true)

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_WEB_DB_SeasonWise_DetailsSelect?M_FinancialYearID=${M_FinancialYearID}&M_MonthsID=${M_MonthsID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_IndicatorID=${M_IndicatorID}&PhotoPath=${PhotoPath}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data.table) {
                    setloading && setloading(false)
                    if (Flag == 'SeasonWiseAnimalDeath') {
                        handleGetExportData && handleGetExportData()
                        setSeasonWiseAnimalDeath && setSeasonWiseAnimalDeath(result.data.table)
                    } if (Flag == "DayWiseAnimalDeath") {
                        handleGetExportData && handleGetExportData()
                        setDayWiseAnimalDeath && setDayWiseAnimalDeath(result.data.table)
                    } if (Flag == "ShiftWiseAnimalDeath") {
                        handleGetExportData && handleGetExportData()
                        setShiftWiseAnimalDeath && setShiftWiseAnimalDeath(result.data.table)
                    }
                    return result.data.table

                } else {
                    setloading(false)
                    return result
                }
            } else {
                // toastError(result.message)
                setloading(false)
                return result
            }
        })
})

const seasonWiseAnimalDataSlice = createSlice({
    name: "seasonWiseAnimalData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(seasonWiseAnimalDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(seasonWiseAnimalDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(seasonWiseAnimalDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastError('Something went wrong')
        });
    },
})

export const seasonWiseAnimalDataReducer = seasonWiseAnimalDataSlice.reducer

// Get Table Data
export const seasonWiseAnimalExportDataAPI = createAsyncThunk("seasonWiseAnimalExportData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthsID,
        M_StateID,
        M_DistrictNameID,
        M_AnimalID,
        PhotoPath,
        Flag,
        M_UserID,
        FromTop,
        ToTop,
        M_IndicatorID,
        setloading,
        token,
        setSeasonWiseAnimalDeath,
        setDayWiseAnimalDeath,
        setShiftWiseAnimalDeath
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DashBoard/Get_WEB_DB_SeasonWise_DetailsSelect?M_FinancialYearID=${M_FinancialYearID}&M_MonthsID=${M_MonthsID}&M_StateID=${M_StateID}&M_DistrictNameID=${M_DistrictNameID}&M_AnimalID=${M_AnimalID}&M_IndicatorID=${M_IndicatorID}&PhotoPath=${PhotoPath}&M_UserID=${M_UserID}&Flag=${Flag}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.data.table) {
                    if (Flag == 'SeasonWiseAnimalDeath') {
                        setSeasonWiseAnimalDeath && setSeasonWiseAnimalDeath(result.data.table)
                    } if (Flag == "DayWiseAnimalDeath") {
                        setDayWiseAnimalDeath && setDayWiseAnimalDeath(result.data.table)
                    } if (Flag == "ShiftWiseAnimalDeath") {
                        setShiftWiseAnimalDeath && setShiftWiseAnimalDeath(result.data.table)
                    }
                    return result.data.table

                } else {
                    setloading(false)
                    return result
                }
            } else {
                // toastError(result.message)
                setloading(false)
                return result
            }
        })
})

const seasonWiseAnimalExportDataSlice = createSlice({
    name: "seasonWiseAnimalExportData",
    initialState: {
        isExportLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(seasonWiseAnimalExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(seasonWiseAnimalExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(seasonWiseAnimalExportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastError('Something went wrong')
        });
    },
})

export const seasonWiseAnimalExportDataReducer = seasonWiseAnimalExportDataSlice.reducer